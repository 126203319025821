export function isAthlete(category){
  if(category == 1 || category == 8)
    return true
  else
    return false
}

export function isCamera(category){
  if(category == 9)
    return true
  else
    return false
}
