import * as React from 'react';
import {useState, useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { isAthlete } from '../../initialize/device_helpers';

const columns = [
  { field: 'serial', headerName: 'Serial', width: 90 },
  { field: 'no', headerName: '選手番号', width: 110 },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstname') || ''} ${
        params.getValue(params.id, 'lastname') || ''
      }`,
  },

  { field: 'lastname', headerName: 'First name', width: 130 },
  { field: 'firstname', headerName: 'Last name', width: 130 },
];

function Athletes(props) {

  // Props
  const {devices, setDevices, online, mobile} = props;

  // State
  const [athletes, setAthletes] = useState([]);

  useEffect(() => {
    if(!devices)
      return;

    let newAthletes = [];
    devices.forEach((device, i)=>{
      console.log(device)
      if(isAthlete(device.category))
        newAthletes.push(device)
    });
    setAthletes(newAthletes);
  }, devices);

  // SelectionModel
  const selectionModel = athletes.filter((r) => r.ischecked).map((r) => r.id)

  const disp_style = mobile ? "athletes_mobile" : "athletes"

  return (
    <div className={disp_style} >
      <DataGrid
        className={disp_style}
        rows={athletes}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[50]}
        checkboxSelection
        selectionModel={selectionModel}
        onCellClick={(params, event, details)=>{
          let device = devices.get(params.id);
          device.handleInputChange(); 
          setDevices(devices => new Map([...devices, [device.id, device]])); 
        }}
  
      />
    </div>
  );
}
export default React.memo(Athletes);
