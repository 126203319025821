import * as React from 'react';
function PanelHeader(props) {
    const {setMenuVisible} = props;
  
    const handleMenuCloseClick = () => {
      setMenuVisible(false);
    };
  
    return (
      <>
        <div className="panel-container">
            <div className="panel-header">
                <span style={{fontSize: `22px`}} >表示メニュー</span>
                <img src="/icon_interface/close.png" alt="panel_close" width="20" height="20" onClick={handleMenuCloseClick}/>
            </div>
        </div>
      </>
    );
}

export default PanelHeader;
