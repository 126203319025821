import * as React from 'react';
import { isAthlete } from '../../initialize/device_helpers';





function Speeds(props) {
  const {heat, devices} = props;


  if(!heat || !devices)
    return null;


  //Find max speed
  let max_id = "";
  let maxspd = 0;
  devices.forEach((device, i)=>{
    if(maxspd < device.speed_5s){
      maxspd = device.speed_5s;
      max_id = device.id;
    }
  });

  let tmp = [];
  devices.forEach((d,i) => {
    tmp.push(d);
  });
  tmp.sort((a, b) => a.no > b.no ? 1 : -1)

  const athletes = [];
  tmp.forEach((device, i) => {

    let head = "speed-head";
    let data = "speed-data";
    if(device.id == max_id){
      head = "speed-head-max";
      data = "speed-data-max";
    }

    if(isAthlete(device.category)){
      athletes.push(
        <span key={device.id}>
          <span key={"s"+device.id} className={head}> {device.no} </span>
          <span key={"d"+device.id} className={data}> {String(device.speed_5s).padStart(2, '0')} km/h </span>


        </span>
      )
    }
  });

  return(
  <div className={"speed-back"}>
    <div className={"discipline"}>{heat.discipline}　{heat.race}　{heat.name}</div>
    <div>
      { athletes }
    </div>
  </div>
  );
}

export default React.memo(Speeds);
