import {is_mode, URLParams} from './initialize/initialize.js';
const urlParams = new URLSearchParams(window.location.search);  

export function to_d(seconds){
    let minutes = Math.floor(seconds / 60);
    let sec = seconds % 60;
		if(minutes == 0 && sec == 0)
			return '--:--'
		else
			return minutes + ":" + toDoubleDigits(sec);
}

function toDoubleDigits(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
    return num;
}

/**
 * calc_distance
 */
//メートル単位で2点間の距離を返却する
export function calc_distance(lat1, lng1, lat2, lng2) {
  lat1 *= Math.PI / 180
  lng1 *= Math.PI / 180
  lat2 *= Math.PI / 180
  lng2 *= Math.PI / 180
  let len = Math.floor(6371.137 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)) * 1000);
  return isNaN(len)? 0 : len
}


//GPS timeをDateオブジェクトに変換
export function convertGpsTime(time){
    let t = new Date();
    let utc = new Date(
      t.getUTCFullYear(),
      t.getUTCMonth(),
      t.getUTCDate(),
      time.substring(0, 2),
      time.substring(2, 4),
      time.substring(4, 6),
      time.split(".")[1],
    );
    return utc;
}

//2点間距離の移動速度を算出
export function calc_velocity(l2, l1, t2, t1) {
    if (t1 == 0)
      return 0;
    else {
      let dt = (Date.parse('2020/01/01 ' + t2) - Date.parse('2020/01/01 ' + t1)) / 1000;
      return (-1 * (l2 - l1) / parseFloat(dt));//符号反転（近づくとマイナスになるから）
    }
}

// クリップボードへコピー（コピーの処理）
export function copyToClipboard (value) {
  navigator.clipboard.writeText(value).then(
  () => {
    // コピーに成功したときの処理
    alert('コピーしました。');
  },
  () => {
    // コピーに失敗したときの処理
    alert('コピーに失敗しました。');
  });
}

export function generateYoutubeUrl(id, isAutoPlay = false, start = undefined) {
  let url = `https://www.youtube.com/embed/${id}`;

  let params = [];

  // 自動再生の有無
  if (isAutoPlay) {
    params.push('autoplay=1');
  }

  // 開始時間の有無
  if (start !== undefined && start !== null) {
    params.push(`start=${start}`);
  }

  // パラメータがある場合はURLに追加
  if (params.length > 0) {
    url += '?' + params.join('&');
  }

  return url;
}

//時間のフォーマット
function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

//時間のフォーマットを整える
//time:秒
export function formatTime(time, mobile){
	return mobile ? formatHMS(time) : formatDT(time);
}


export function formatDT(t) {
  let __dt = new Date((t)*1000);
  let year = __dt.getFullYear();
  let month = zeroPad(__dt.getMonth()+1, 2);
  let date = zeroPad(__dt.getDate(), 2);
  let hours = zeroPad(__dt.getHours(), 2);
  let minutes = zeroPad(__dt.getMinutes(), 2);
  let seconds = zeroPad(__dt.getSeconds(), 2);
  return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
};

export function formatHMS(t) {
  let __dt = new Date((t)*1000);
  let hours = zeroPad(__dt.getHours(), 2);
  let minutes = zeroPad(__dt.getMinutes(), 2);
  let seconds = zeroPad(__dt.getSeconds(), 2);
  return hours + ':' + minutes + ':' + seconds;
};

//replayモードの判定
export function is_replay(){
	if(is_mode() == "replay")
		return true;
	else
		return false;
}

//sidepanelがロードレース
export function is_road_sidepanel(){
	if(urlParams.get("side_panel") === "road"){
		return true;
	} else {
		return false;
	}
}
//初期表示時に自動フォーカスする
export function autoFocus(){
	if(urlParams.get("auto_focus") === "1"){
		return true;
	} else {
		return false;
	}
}

//Elevationデータを表示する／しない
export function showElevation(){
	if(urlParams.get("show_elevation") === "1"){
		return true;
	} else {
		return false;
	}
}
//Elevationデータを取得するためのZoomレベル（コース全体がはいるように）
export function elevationZoom(){
    var zoom_level =  10
    zoom_level = urlParams.get("elevation_zoom")?urlParams.get("elevation_zoom"): 10
    return zoom_level
}
export function is_training() {
  let params = new URLSearchParams(window.location.search);
  return params.get('training') === '1';
}

// 航跡の長さ
export function lineLength(){
    return urlParams.get("line_length")? urlParams.get("line_length"):15 
}

export function map_style(){
	return URLParams()['map_style']
}

// 時間を時刻で表示するか、スタートからの差で表示するか
export function is_counttime(){
	if(urlParams.get("counttime") === "1"){
		return true;
	} else {
		return false;
	}
}

// クリックした時に、マーカーツールチップを表示させない
export function hidden_tooltip(){
	if(urlParams.get("hidden_tooltip") === "1"){
		return true;
	} else {
		return false;
	}
}

// クリックした時に、マーカーツールチップを表示させない
export function greenBack(){
	if(urlParams.get("green_back") === "1"){
		return true;
	} else {
		return false;
	}
}

// 文字数を制限して表示
export function trim_name(name, len){
    if (name.length > len) {
    return name.slice(0, len);  // len文字まで切り詰める
  } else {
    return name;  // そのまま返す
  }
}

// 文字数を制限して表示
export function trim_name2(name, len){
    if (name.length > len) {
    return name.split(" ")[0];  // len文字まで切り詰める
  } else {
    return name;  // そのまま返す
  }
}
