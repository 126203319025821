// ArrowLayer.js
import React from 'react';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';

// 3DモデルのURL
const MODEL_URL='/glb/wind_arrow.glb';

const ArrowLayer = ({ center, windDirection }) => {
	const arrowLength = 200; // 矢印の長さ
	const arrowCount = 10; // 矢印の数
  // 中心座標からの距離と方向に基づいて矢印を配置する関数
  const generateArrows = (center, arrowLength, arrowCount, windDirection) => {
    const [centerLng, centerLat] = center;
    const distance = 0.072; // 8km (0.018:2km) 四方に広げる (1度 = 約111km)

    const arrows = [];

    for (let i = -arrowCount / 2; i < arrowCount / 2; i++) {
      for (let j = -arrowCount / 2; j < arrowCount / 2; j++) {
        const lng = centerLng + i * distance / arrowCount;
        const lat = centerLat + j * distance / arrowCount;
        arrows.push({
          position: [lat, lng, 0],
          wind_direction: windDirection,
        });
      }
    }

    return arrows;
  };

  // 矢印のデータを生成
  const buoy = generateArrows(center, arrowLength, arrowCount, windDirection);

	/*
  // ScenegraphLayerのインスタンスを返す
  return new ScenegraphLayer({
    id: 'arrow-layer',
    data: arrowData,
    scenegraph: MODEL_URL,
    getPosition: d => d.position,
    getOrientation: d => [0, d.direction, 90], // 3Dモデルの回転を制御
    sizeScale: arrowLength,
    _animations: {
      '*': { speed: 1 } // アニメーションのスピード調整
    }
  });*/

  return new ScenegraphLayer({
    id: 'arrow-layer2',
    data: buoy,
    scenegraph: MODEL_URL,
		pickable: true,
		sizeScale: 15,
		sizeMinPixels: 1.5, // この値で最小サイズの制約を設定
		sizeMaxPixels: 15,  // この値で最大サイズの制約を設定
		getColor: d => [255,255,255,50],
		getPosition: d => [
			d.position[0] || 0,
			d.position[1] || 0,
			d.position[2] || 0
		],
		getOrientation: d => [0, -d.wind_direction, -90],
		//getOrientation: d => [0, 0, 90],
  });

};

export default ArrowLayer;
