import React, { useState, useEffect } from "react";
import SceneryIcons from "../marker/scenery_icons.js";

function YokohamaIcons(props) {
  const {zoomLevel, viewBearing} = props;
  const [isYokohamaCup, setIsYokohamaCup] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("yokohama") === "1") {
      setIsYokohamaCup(true);
    }
  }, []);

  return (
    <>
      {isYokohamaCup && (
        <SceneryIcons
            zoomLevel={zoomLevel}
            viewBearing={viewBearing}
        />
      )}
    </>
  );
}

export default React.memo(YokohamaIcons);
