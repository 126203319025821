import * as React from 'react';
function PanelItem(props) {
    const {imagePath, title, onClick = () => {}, isActive = false} = props;
  
    const imageStyle = isActive ? { border: `1px solid #DE7244`, borderRadius: '10px' } : { border: "none" };
    const titleStyle = isActive ? { color: `#DE7244` } : {};

    return (
      <div className="panel-content-item" onClick={onClick}>
        <img src={imagePath} alt="aerial" width="40" height="40" style={imageStyle} />
        <span style={titleStyle}>{title}</span>
      </div>
    );
}

export function PanelItemRoute(props) {
  const {imagePath, title, onClick, color, isActive } = props;

  const imageStyle = isActive ? { border: `1px solid #DE7244`, borderRadius: '10px' } : { border: "none" };
  const titleStyle = isActive ? { color: `#DE7244` } : {};
  const underLineStyle = { width: `40px`, height: `0px`, border: `4px solid ${color}`, borderRadius: `10px`};

  return (
    <div className="panel-content-item" onClick={onClick}>
      <img src={imagePath} alt="aerial" width="40" height="40" style={imageStyle} />
      <span style={titleStyle}>{title}</span>
      <div style={underLineStyle}></div>
    </div>
  );
}

export default PanelItem;
