import * as React from 'react';
import PanelContent from "./panel_content";
import { PanelItemRoute } from "./panel_item";

function PanelContentRoute(props) {
  const {visibleRoutes, setVisibleRoutes} = props;

  if (!visibleRoutes.Swim.exists && !visibleRoutes.Bike.exists && !visibleRoutes.Run.exists) {
    return <></>;
  }

  return (
    <PanelContent title="競技の種類">
      {visibleRoutes.Swim.exists && (
        <PanelItemRoute
          imagePath={"/icon_interface/swim.svg"}
          title={"スイム"}
          color={visibleRoutes.Swim.color}
          isActive={visibleRoutes.Swim.visible}
          onClick={()=>{
            setVisibleRoutes((prev) => {
              return {...prev, 
                      Swim: {...prev.Swim, visible: !prev.Swim.visible}}
            })}
          }
        />
      )}
      {visibleRoutes.Bike.exists && (
        <PanelItemRoute
          imagePath={"/icon_interface/bike.svg"}
          title={"バイク"} 
          color={visibleRoutes.Bike.color}
          isActive={visibleRoutes.Bike.visible}
          onClick={()=>{
            setVisibleRoutes((prev) => {
              return {...prev, 
                      Bike: {...prev.Bike, visible: !prev.Bike.visible}}
            })}
          }
        />
      )}
      {visibleRoutes.Run.exists && (
        <PanelItemRoute
          imagePath={"/icon_interface/run.svg"}
          title={"ラン"}
          color={visibleRoutes.Run.color}
          isActive={visibleRoutes.Run.visible}
          onClick={()=>{
            setVisibleRoutes((prev) => {
              return {...prev, 
                      Run: {...prev.Run, visible: !prev.Run.visible}}
            })}
          }
        />
      )}
    </PanelContent>
  );
}

export default PanelContentRoute;
