import React, { useState } from 'react';
import Panel from './panel';
import Button from './button';

function Menu(props) {
  const { setStyle, visibleRoutes, setVisibleRoutes, focusCamera, setFocusCamera, showCheckpoint, setShowCheckpoint } = props;
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuButtonClick = () => {
    setMenuVisible(true);
  };

  return (
    <>
      {menuVisible ? (
        <Panel menuVisible={menuVisible} setMenuVisible={setMenuVisible} setStyle={setStyle} visibleRoutes={visibleRoutes} setVisibleRoutes={setVisibleRoutes}
                focusCamera={focusCamera} setFocusCamera={setFocusCamera} showCheckpoint={showCheckpoint} setShowCheckpoint={setShowCheckpoint}/>
      ) : (
        <Button handleMenuButtonClick={handleMenuButtonClick} />
      )}
    </>
  );
}

export default React.memo(Menu);
