import axios from 'axios';

export default class API {

  // Heat
  static getHeat({heat_id, host, token, onSuccess, onError}){
    axios.get(`${host}/api/heats/${heat_id}`, 
               {headers: {Authorization: `Token ${token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // HeatSensors
  static getHeatSensors({heat_id, host, token, onSuccess, onError}){
    axios.get(`${host}/api/heats/${heat_id}/heat_sensors`, 
               {headers: {Authorization: `Token ${token}`}})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // ReplayData
  static getReplayData({url, onSuccess, onError}){
    axios.get(url)
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
          onError(error);
        });
  }

  // Ranking
  static getRankingWithHeat({heat_id, host, onSuccess}){
    axios.get(`${host}/api/rankinglists/${heat_id}`, 
               {headers:
							 					{
												'Content-Type': 'application/json',
												"Access-Control-Allow-Origin": "*",
							 }})
        .then((response) => {
          onSuccess(response);
        })
        .catch((error) => {
					console.log("[error]getRankingWithHeat:", error)
        });
  }


}
