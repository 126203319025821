import * as React from 'react';
import IconFocus from './icon_focus';

function IconCamera(props) {
  const {color, offsetY, focus} = props;

  return(
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          transform: `translate(0px, ${offsetY}px)`
        }}
        >
        <path d="M23 7L17 12L23 17V7Z"
              fill={color}
              stroke="#323438"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M15 5H4C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H15C16.1046 19 17 18.1046 17 17V7C17 5.89543 16.1046 5 15 5Z"
              fill={color}
              stroke="#323438"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>

      { focus &&
        <IconFocus objectSize={30} offset={{x: -10, y: -5}}/>
      }
    </>
  );
}

export default React.memo(IconCamera);
