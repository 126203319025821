import * as React from 'react';
import {useState, useEffect} from 'react';
import {Marker, Popup} from 'react-map-gl';

const a="M271.66,135.63c-1.63,0-3.33,.06-4.91,.15l-2.15,.2c-1.02,.09-2.03,.16-3.03,.29l-2.49,.38c-.8,.12-1.61,.23-2.45,.39-.41,.08-.81,.17-1.21,.26l-1.13,.24c-.81,.17-1.62,.34-2.46,.55-.56,.14-1.14,.31-1.7,.47l-.49,.14c-.82,.23-1.65,.46-2.43,.71-.77,.24-1.52,.5-2.24,.75-.77,.27-1.55,.54-2.35,.85-.75,.29-1.49,.59-2.24,.91-.74,.3-1.46,.62-2.17,.94-.77,.34-1.53,.7-2.25,1.05-.7,.34-1.39,.7-2.03,1.03-.77,.4-1.54,.8-2.29,1.23-.63,.35-1.24,.72-1.9,1.11-.76,.45-1.52,.91-2.25,1.38-.43,.27-.84,.55-1.26,.84l-.49,.33c-.77,.52-1.54,1.03-2.29,1.58l-1.73,1.31c-.71,.54-1.43,1.07-2.04,1.58l-1.49,1.27c-.69,.58-1.4,1.14-2.04,1.74-.27,.26-.54,.52-.83,.79-3.26,3.03-6.3,6.29-9.02,9.68l-27.58,34.43-27.38-34.3c-2.77-3.45-5.86-6.79-9.19-9.86-.26-.24-.51-.48-.79-.74-.68-.63-1.42-1.22-2.26-1.93l-1.16-1c-.72-.59-1.46-1.13-2.2-1.7l-1.67-1.27c-.75-.55-1.53-1.06-2.35-1.61l-.37-.26c-.44-.29-.87-.59-1.3-.87-.77-.48-1.54-.94-2.25-1.37-.63-.38-1.27-.76-1.91-1.12-.75-.43-1.5-.82-2.28-1.23-.67-.35-1.35-.69-2.04-1.03-.75-.37-1.52-.73-2.26-1.06-.72-.33-1.45-.64-2.17-.95-.75-.31-1.49-.61-2.25-.9-.77-.29-1.55-.57-2.32-.84-.75-.26-1.5-.52-2.23-.75-.81-.26-1.64-.49-2.5-.74l-.46-.12c-.56-.16-1.13-.32-1.74-.47-.81-.21-1.63-.37-2.64-.59l-.93-.2c-.41-.09-.82-.19-1.22-.26-.8-.15-1.62-.26-2.43-.39l-2.48-.38c-1-.12-2.02-.2-3.03-.29l-1.98-.18c-1.73-.11-3.45-.17-5.08-.17C38.51,135.63,0,176.51,0,226.77c0,72.48,105.96,148.6,178.73,187.91,72.79-39.31,178.75-115.43,178.75-187.91,0-50.26-38.51-91.14-85.82-91.14Zm-132.32,218.37l48.99-69.87-65.05,.8,89.95-75.5-36.94,58.63,61.84,13.65-98.79,72.28Z"
const b="M97.08,116.63c-1.11-6.67-1.95-10.29-3.34-15.01l-3.75-11.82H50.37l-3.75,11.82c-1.81,5.56-2.78,9.87-3.48,15.01H10.62c2.08-4.17,3.34-7.51,6.67-16.96L47.32,15.99c2.5-6.81,3.75-10.98,4.45-15.01h36.28c.97,4.03,1.95,7.65,4.73,15.01l30.16,83.68c2.64,7.37,4.87,13.07,6.67,16.96h-32.53ZM69.97,24.74l-12.93,41.7h26.13l-13.21-41.7Z"
const c="M226.35,117.6c-5.98-.69-10.98-.97-22.94-.97h-43.79c-10.56,0-14.46,.14-20.43,.42,.83-5.56,1.11-10.7,1.11-20.29V20.85c0-8.62-.28-13.48-1.11-20.3,5.7,.28,9.59,.42,20.43,.42h44.34c9.45,0,14.18-.28,20.71-.97V25.99c-6.39-.69-11.4-.97-20.71-.97h-36.14v20.43h30.03c9.17,0,12.51-.14,20.85-.97v25.72c-6.53-.7-11.26-.97-20.85-.97h-30.03v23.35h35.86c10.56,0,16.68-.28,22.66-.97v25.99Z"
const d="M241.77,20.99c0-9.45-.28-14.04-1.25-20.43,5.56,.28,9.04,.42,20.57,.42h29.05c17.1,0,30.86,4.73,40.17,13.76,10.15,10.01,15.29,24.6,15.29,43.09s-5.56,34.33-16.12,44.9c-9.31,9.17-22.52,13.9-39.06,13.9h-29.33c-10.7,0-14.6,.14-20.43,.42,.83-6.12,1.11-10.7,1.11-20.29V20.99Zm46.15,71.31c9.04,0,15.43-2.36,20.43-7.37,5.7-5.84,8.76-15.15,8.76-26.69s-2.64-20.43-8.06-25.86c-4.87-4.87-11.54-7.09-21.55-7.09h-18.21V92.3h18.63Z"

const SIZE = 3;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function AnimationAED(props) {
  const {position, name, speed, time, color, duration} = props;
  const X = 0; 
  const Y = 5; 
  const [pos, setPos]   = useState({lat:0, lon:0, angle:0});
  const [info, setInfo] = useState(false);
  const [diff, setDiff] = useState({x:X, y:Y});
  const [prevtime, setPrevtime]   = useState(null);

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    //更新がなければ処理しない
    if( time == prevtime ){
      //console.log("same time.")
      return;
    } else{
      setPrevtime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  pos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }

      let v = {
        lat:   (position.lat   - pos.lat),
        lon:   (position.lon   - pos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: from.angle + v.angle * d
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setPos, v, Date.now(), pos, 0));
    }
  },[position])

  // Tooltipの表示位置
  useEffect(() => {
    if(info){// hide tooltip
      setDiff({x:X, y:Y-0.518});
    } else {// show tooltip
      setDiff({x:X, y:Y});
    }
  },[info])


  let dir = 1;
  let dir2 = 90;
  let tmp = pos.angle;
  if(tmp<0)
    tmp = 360+pos.angle;
  if(tmp > 180){
    dir = -1;
    dir2 = -90;
  }

  return(
    <>
    <Marker longitude={pos.lon} latitude={pos.lat}>

      <div className="tooltip" onClick={()=>(setInfo(!info))} >{name}
        {info && (
          <>
            <div className="tooltip-speed">SPD:{speed}km/h</div>
            <div className="tooltip-time">UPD:{time}</div>
          </>
        )}
      </div>

      <svg
        height={SIZE}
//0 0 357.48 414.68
        viewBox="0 0 45 45"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          transform: `translate(-20px,-40px)`,
          overflow: 'visible'
        }}
        onClick={() => (setInfo(!info))}
      >
        <path d={a} fill={color}/>
        <path d={b} fill={color}/>
        <path d={c} fill={color}/>
        <path d={d} fill={color}/>
        <circle cx={"164.266"} cy={"344.922"} r={"9.078"} fill={color}/>
      </svg>
    </Marker>
    </>
  );
}

export default React.memo(AnimationAED);
