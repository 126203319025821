import React, { Component } from 'react';

// AutoPlay用
var lastMoveTimeSec = 0;


//時間バーを変更
export function handleSelectDataRange(timerRef, setCurrentReplayTimeSec, updatedReplayTimeSec, incrementSecond, isPlaying, setIsPlaying, devices, setDevices, dataRange, setDelay, currentReplayTimeSec){

	//auto replay を一旦停止
	clearInterval(timerRef.current);
	const wasPlaying = isPlaying;
	setIsPlaying(false);
	
	//更新時間を与え、deviceMapを更新
	const updatedReplayTimeDate = new Date(updatedReplayTimeSec * 1000);
	resetAndMove(updatedReplayTimeDate, devices, setDevices);
	lastMoveTimeSec = updatedReplayTimeSec;
	
	setCurrentReplayTimeSec(updatedReplayTimeSec);

	//youtubeの開始時間を変更
	let nt = updatedReplayTimeSec - dataRange.min;// 現在時刻 - heat開始時刻
	setDelay(nt)

	//再生中であった場合は再開
	if (wasPlaying) {
		autoPlay(timerRef, incrementSecond, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying)
	}
}

//カウントアップ倍率の変更
export function handleChangeIncrementSecond(e, isPlaying, setIncrementSecond,incrementSecond, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying, timerRef){
		setIncrementSecond( e.target.value );
		if(isPlaying)
			autoPlay(timerRef, e.target.value, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying)
}

//再生or停止
export function handleChangePlayState(timerRef, isPlaying, setIsPlaying, incrementSecond, devices, setDevices, setCurrentReplayTimeSec,dataRange, currentReplayTimeSec){
	if(isPlaying){
		//停止
		clearInterval(timerRef.current);
		setIsPlaying(false);
	} else {
			autoPlay(timerRef, incrementSecond, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying)
	}
}


// オートプレイ用 = 定期的に呼び出される。
// デバイスの移動を行う。
function slide(incrementSecond, timerRef, setCurrentReplayTimeSec, devices, setDevices, dataRange, setIsPlaying ){
	//更新する再生時間の取得
	const updatedReplayTimeSec = lastMoveTimeSec + Number(incrementSecond)

	//動かす
	const updatedReplayTimeDate = new Date((updatedReplayTimeSec) * 1000);

	move(updatedReplayTimeDate, devices, setDevices);
	lastMoveTimeSec = updatedReplayTimeSec;

	//再生時間の更新
	setCurrentReplayTimeSec((prevTime) => prevTime + Number(incrementSecond));

	//終了条件
	let endDate = new Date(dataRange.max*1000);
	if(updatedReplayTimeDate >= endDate){
		setCurrentReplayTimeSec(dataRange.max);
		console.log("Fin.")
		clearInterval(timerRef.current);
		setIsPlaying(false);
	}
}

// デバイスの移動を定期実行する開始ポイント
export function autoPlay(timerRef, incrementSecond, setCurrentReplayTimeSec, devices, setDevices, dataRange, currentReplayTimeSec, setIsPlaying){
	clearInterval(timerRef.current); 
	if (lastMoveTimeSec === 0) {
		// 初回のみ、現在時刻をセット
		lastMoveTimeSec = currentReplayTimeSec;
	}

	setIsPlaying(true);
	const intervalMs = 1000 / incrementSecond;
	timerRef.current = setInterval(()=>slide(1, timerRef, setCurrentReplayTimeSec, devices, setDevices, dataRange, setIsPlaying), intervalMs);
}

// デバイス単体の動作制御。
// それまでのログをリセットする用。
function resetAndMove(time, devices, setDevices){

  if(!devices)
    return

  let deviceMap = new Map();
  devices.forEach(function(device, i) {
    device.resetMovementData();
    device.move(time);
    deviceMap.set( device.serial, device);
  });
  setDevices(deviceMap);
}

// デバイス単体の動作制御
function move(time, devices, setDevices){

  if(!devices || !devices.size > 0)
    return

  let deviceMap = new Map();
  devices.forEach(function(device, i) {
    device.move(time);
    deviceMap.set( device.serial, device);
  });

  setDevices(deviceMap);
}

