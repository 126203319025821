import * as React from 'react';
import {useState, useEffect} from 'react';
import AthleteList from '../side_panel/athlete_list.js';
import ReplaySidePanelRoadRace from '../side_panel/replay_side_panel_roadrace.js';
import SidePanelSelectSpeed from '../side_panel/select_speed.js';
import SidePanelRanking from '../side_panel/side_panel_ranking.js';
import SidePanelRoadRace from '../side_panel/side_panel_road_race.js';
import {is_individual_view, is_ranking } from '../../initialize/initialize.js';
import Grid from '@mui/material/Grid';
import { isAthlete } from '../../initialize/device_helpers.js';
import { Button, IconButton } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel, ListItemText } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {is_road_sidepanel} from '../../helpers';

export default function SidePanelHandler( props ) {
 
  const { is_mode, is_mobile, devices, heat, handleChangeSelectedDevice, height_desktop, height_side_panel, selectedValue, setSelectedValue, setDevices, course, setRanking, setWindLine } = props;

  const [searchNo, setSearchNo] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();


  useEffect(() => {
		let teamnames = [];
		devices.forEach( (d, key) => {
			if(isAthlete(d.category)){
				//グループの精査
				let flg=false;
				for(let g=0; g < teamnames.length; g++){
					if(teamnames[g].name === String(d.club)){
						flg = true;
					}
				}
				if(!flg){
					teamnames.push({name:String(d.club), img: `/j-pro/${d.club}.png`})
				}
			}
		});//loop devices

		//Sort
		teamnames.sort(
			function(a,b){
					return (a.name < b.name) ? -1 : 1;  //オブジェクトの降順ソート
			}
		)
		setGroups(teamnames);
	}, [devices])


	const searchAthlete = (query, focus) =>{
    setSearchNo(query);
    // devicesの中で、検索文字列とプレフィックスが一致するもののみマーカー表示フラグをtrueにする
    let newDevices = new Map();
    [...devices].forEach(([key, d]) => {
      //if (d.no.startsWith(query)) {
      if( d.ori_searchstr.toLowerCase().indexOf(query.toLowerCase()) > -1 ){
        d.showMarker = true;
				if(focus)
					d.ischecked = true;
				if(query=='')
					d.ischecked = false;
      }
      else {
        d.showMarker = false;
				if(focus)
					d.ischecked = false;
      }
      newDevices.set(key, d)
    });
    setDevices(newDevices);
	}


  const handleSearchChange = e => {
    const query = e.target.value;
    searchAthlete(query, false);
  };


  const handleSelectTeam = (event) => {
    const value = event.target.value;
    setSelectedTeam(value);
		if(value != ''){
			searchAthlete(value, true);
		} else {
			searchAthlete(value, false);
		}
  };

  const searchNoBox = (
    is_individual_view() ?
      <div style={{zIndex: 100}} >
				<label >選手検索</label>
				<input
				type="text"
				placeholder="レースNo / 名前"
				style={{ fontSize: '18px', width: '50%', padding: '8px', margin: '5px'}}
				value={searchNo}
				onChange={handleSearchChange}
				/>
				<IconButton
					color="primary"
          onClick={() => {
            setSelectedTeam('');
            searchAthlete('', false);
          }}

					sx={{ border: '1px solid', borderColor: 'primary.main' }}
				>
					<ClearIcon />
				</IconButton>
			{
				is_road_sidepanel()?
    <FormControl fullWidth>
      <InputLabel>Select a team</InputLabel>
      <Select
        value={selectedTeam}
        onChange={handleSelectTeam}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '') {
            return <em>Select a team</em>;
          }
          const team = groups.find(t => t.name === selected);
          return (
            team ?
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={team.img}
                  alt={team.name}
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
                {team.name}
              </div>
            ) : (<></>)
          );
        }}
      >
        <MenuItem value="">
          <em>Select a team</em>
        </MenuItem>
        {groups.map((team, index) => (
          <MenuItem key={index} value={team.name}>
            <img
              src={team.img}
              alt={team.name}
              style={{ width: '20px', height: '20px', marginRight: '10px' }}
            />
            <ListItemText primary={team.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
			:
				<></>
			}
      </div>
      : <></>
  )


  const side_panel_desktop = (
    <Grid style={{zIndex: 100}}  item xs={12} sm={3}>
      <div style={{zIndex: 100, height: height_side_panel, overflowY: 'auto'}}>
				<SidePanelSelectSpeed selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        {searchNoBox}
				{ is_road_sidepanel() ?
					<ReplaySidePanelRoadRace devices={devices} selectedValue={selectedValue} handleChangeSelectedDevice={handleChangeSelectedDevice} />
					:
					<AthleteList devices={devices} selectedValue={selectedValue} handleChangeSelectedDevice={handleChangeSelectedDevice} setWindLine={setWindLine} />
				}
      </div>
    </Grid>
  )

  const side_panel_mobile = (
    <Grid style={{zIndex: 100}} item xs={12} sm={3}>
			<SidePanelSelectSpeed selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
      {searchNoBox}
			<AthleteList devices={devices} selectedValue={selectedValue} handleChangeSelectedDevice={handleChangeSelectedDevice}  setWindLine={setWindLine} />
    </Grid>
  )


	const live_side_panel = (
		( is_road_sidepanel() ? 
				(
          <Grid style={{zIndex: 100}}  item xs={12} sm={3}>
            <div style={{zIndex: 100, height: height_side_panel, overflowY: 'auto'}}>
              {searchNoBox}
              <SidePanelRoadRace devices={devices} heat={heat} handleChangeSelectedDevice={handleChangeSelectedDevice} course={course} setRanking={setRanking} />
            </div>
          </Grid>
        )
			:(
          <Grid style={{zIndex: 100}}  item xs={12} sm={3}>
            <div style={{zIndex: 100, height: height_side_panel, overflowY: 'auto'}}>
              {searchNoBox}
							<SidePanelRanking  devices={devices} heat={heat} handleChangeSelectedDevice={handleChangeSelectedDevice}  course={course}  setRanking={setRanking}  setWindLine={setWindLine}  />
            </div>
          </Grid>
			)
		)
	)

  return (
		<>
      {
				is_mobile? (is_mode == "live" && is_ranking() ? live_side_panel : side_panel_mobile )
				:
      	(is_mode == "live" && is_ranking() ? live_side_panel : side_panel_desktop)
			}
		</>
	)
}
