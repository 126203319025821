import * as React from 'react';
import IconFocus from './icon_focus';

const D_SUP = 'M17.72 15.49C17.72 23.45 15.86 29.92 13.57 29.92C11.28 29.92 9.42 23.45 9.42 15.49C9.42 7.53 11.28 1.06 13.57 1.06C15.86 1.06 17.72 7.53 17.72 15.49Z';
const D_RECTANGLE = 'M14.65 10.76C15.65 10.76 16.47 11.57 16.47 12.58C16.47 14.99 16.47 20.42 16.47 22.83C16.47 23.84 15.65 24.65 14.65 24.65C13.85 24.65 13.25 24.65 12.45 24.65C11.44 24.65 10.63 23.84 10.63 22.83C10.63 20.42 10.63 14.99 10.63 12.58C10.63 11.57 11.44 10.76 12.45 10.76C13.25 10.76 13.85 10.76 14.65 10.76Z';
const SIZE = 25;
const posDiffXIconFocus = -22;
const posDiffYIconFocus = 2;

function IconSup(props) {
  const {offset, rotate, color, focus, onClick} = props;
  const defaultOffset = -SIZE / 2;

  return(
    <>
      <svg
        height={SIZE}
        viewBox="0 0 24 30"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          transform: `translate(${defaultOffset + offset.x}px,${defaultOffset + offset.y}px) rotate(${rotate}deg)`
        }}
        onClick={onClick}
      >
        <path d={D_SUP} fill={color}/>
        <path d={D_RECTANGLE} fill="#fff" />
      </svg>

      { focus &&
        <IconFocus objectSize={SIZE} offset={{x: offset.x + posDiffXIconFocus, y: offset.y + posDiffYIconFocus}}/>
      }
    </>
  );
}

export default React.memo(IconSup);
