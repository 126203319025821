import React from "react";

import PanelContent from "./panel_content";
import PanelItem from "./panel_item";

function PanelContentMapDetails(props) {
  const {focusCamera, setFocusCamera} = props;

  return (
    <PanelContent title="地図の詳細">
      <PanelItem imagePath={"/icon_interface/flag_start.svg"} title={"Start"}/>
      <PanelItem imagePath={"/icon_interface/flag_finish.svg"} title={"Finish"}/>
      <PanelItem imagePath={"/icon_interface/camera.svg"} title={"LIVE"} isActive={focusCamera}
        onClick={() => {setFocusCamera((prev) => {return !prev})}}/>
    </PanelContent>
  );
}

export default PanelContentMapDetails;
