import * as React from 'react';
function Button(props) {
    const {handleMenuButtonClick} = props;
  
    return (
          <div
            onClick={handleMenuButtonClick}
            className={'menu-button'}
          >
            <img src="/icon_interface/menu.svg" alt="Menu" width="40" height="40"/>
          </div>
    );
  }
  
  export default Button;
  
