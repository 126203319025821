import * as React from 'react';
import {useState, useEffect} from 'react';
import {Marker, Popup} from 'react-map-gl';


//const ICON = 'M11.2 1.5L11.81 2.12L12.39 3.23L12.99 3.92L13.2 5.22L13.51 6.2L13.7 7.37L13.76 9.14L13.7 10.69L13.76 11.76L13.57 13.06L13.32 14.37L12.99 15.67L12.76 16.7L12.26 18.02L11.45 19.59L10.76 20.9L8.07 20.9L7.32 19.59L6.64 18.02L6.14 16.7L5.91 15.87L5.57 14.37L5.32 13.06L5.14 11.76L5.2 10.62L5.2 9.14L5.26 7.17L5.45 6.13L5.91 5.22L6.07 3.92L6.64 3.3L7.26 2.05L7.7 1.31L8.27 0.67L8.7 0L9.45 0'

const a="M85.333,77.888v391.445C85.333,510.805,238.549,512,256,512s170.667-1.195,170.667-42.667V77.888 c-28.48,19.093-85.44,28.779-170.667,28.779S113.835,96.981,85.333,77.888z"

const b="M256,85.333c105.28,0,170.667-16.363,170.667-42.667c0-1.621-0.405-3.115-1.067-4.48 C415.872,5.141,301.653,0.661,265.493,0.085C262.336,0.064,259.243,0,256,0s-6.336,0.064-9.515,0.085 C210.325,0.661,96.107,5.12,86.379,38.187c-0.64,1.365-1.045,2.859-1.045,4.48C85.333,68.971,150.72,85.333,256,85.333z"

const SIZE = 3;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function AnimationBuoy(props) {
  const {position, name, speed, time, color, duration} = props;
  const X = 0; 
  const Y = 5; 
  const [pos, setPos]   = useState({lat:0, lon:0, angle:0});
  const [info, setInfo] = useState(false);
  const [diff, setDiff] = useState({x:X, y:Y});
  const [prevtime, setPrevtime]   = useState(null);

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    //更新がなければ処理しない
    if( time == prevtime ){
      //console.log("same time.")
      return;
    } else{
      setPrevtime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  pos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }

      let v = {
        lat:   (position.lat   - pos.lat),
        lon:   (position.lon   - pos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: from.angle + v.angle * d
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setPos, v, Date.now(), pos, 0));
    }
  },[position])

  // Tooltipの表示位置
  useEffect(() => {
    if(info){// hide tooltip
      setDiff({x:X, y:Y-0.518});
    } else {// show tooltip
      setDiff({x:X, y:Y});
    }
  },[info])


  return(
    <>
    <Marker longitude={pos.lon} latitude={pos.lat}>

      <div className="tooltip-marker" onClick={()=>(setInfo(!info))} >{name}
        {info && (
          <>
            <div className="tooltip-speed">SPD:{speed}km/h</div>
            <div className="tooltip-time">UPD:{time}</div>
          </>
        )}
      </div>

      <svg
        height={SIZE}
        viewBox="200 50 60 60"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          transform: `translate(0px,-20px) rotate(0deg) scale(0.35,0.5)`,
          overflow: 'visible'
        }}
        onClick={() => (setInfo(!info))}
      >
        <path d={a} fill={color}/>
        <path d={b} fill={color}/>
        <circle cx={"164.266"} cy={"344.922"} r={"9.078"} fill={color}/>
      </svg>
    </Marker>
    </>
  );
}

export default React.memo(AnimationBuoy);
