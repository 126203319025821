import * as React from 'react';
import {MapStylePicker} from '../control/controls';
import Checkbox from '@mui/material/Checkbox';

function Settings(props) {

  const { mapstyle, setStyle, mobile, showWindLine, setShowWindLine } = props;
  const disp_style = mobile ? "settings_mobile" : "settings"

  return (
  <div className={disp_style}>
    <br />
    <p> 地図スタイルの変更  </p>
    <MapStylePicker onStyleChange={setStyle} currentStyle={mapstyle}/>

    <br />
    <br />
    <p> 風向ラインの表示 <Checkbox color={"primary"} checked={showWindLine} onClick={ () => setShowWindLine(!showWindLine)} />  </p>
    <br />

  </div>
  );
}

export default React.memo(Settings);
