import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import Radio from '@mui/material/Radio';

export default function SidePanelSelectSpeed( props ) {

  const { selectedValue, setSelectedValue } = props;

	const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(event.target.value);
	};

	return(
			<div className={'select-speed'}>
				<Radio
					checked={selectedValue === 'spd'}
					onChange={handleChangeRadio}
					value="spd"
					name="radio-buttons"
					inputProps={{ 'aria-label': 'A' }}
				/>
				 Speed 　

				<Radio
					checked={selectedValue === 'spd'}
					checked={selectedValue === 'pace'}
					onChange={handleChangeRadio}
					value="pace"
					name="radio-buttons"
					inputProps={{ 'aria-label': 'B' }}
				/>
				pace 分/km
			</div>
	)
}
