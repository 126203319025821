import React from 'react';
import Checkbox from './Checkbox';
import {calc_distance, convertGpsTime, to_d, lineLength} from '../helpers';
import { isAthlete } from './device_helpers';
import axios from 'axios';

export default class Device {

  constructor (id, d){
    this.serial = id// アイコンのスタイル変更で使用予定
    this.id = id
    this.key = this.serial;
    //-------------------
    this.lat = 0.0;
    this.lon = 0.0;
    this.angle = 0.0;
    this.color = d.color ? d.color : "#"+Math.random().toString(16).slice(-6);
		//for replay?
    this.color = (d.color_code!=undefined && d.color_code.length!="") ? d.color_code : this.color;
    //-------------------
    this.name =  d.name
    this.speed = 0
    this.rank = 0
    this.category = d.category
    this.nation =  d.nation?  d.nation : ''
    this.no = d.sail_number
    this.athlete_id = d.athlete_id
    this.firstname = d.firstname
    this.lastname = d.lastname
		//nationがJPN or NULL or undefinedの場合
    this.fullname = ((d.nation=="JPN") || (!d.nation)) ? this.lastname+" "+this.firstname : this.firstname+" "+this.lastname
    this.disp_name = isAthlete(d.category) ? 
                      this.no ? this.no+"/"+this.lastname : this.lastname
                      :  d.name
    //this.disp_name =  this.no //test
    this.club = d.club
    this.rotate = 0;
    this.ischecked = false;//フォーカス
    this.showMarker = true;//マーカーの表示
    this.group_id = d.group_id;
    this.group_name = d.group_name;
    this.color_code = d.color_code? d.color_code : this.color;
    this.status = "";
    this.geofence = [];
    this.ori_searchstr = d.name + d.sail_number + d.firstname + d.lastname + d.club;
    this.searchstr = this.ori_searchstr;
    this.emergency =   false;
    this.emergency_at = null;
    this.time = null;
    this.time2 = null;//デバッグ用
    this.visible = true;
    this.elevation = 0
    this.last_fetch = new Date(2000, 11);
    //------------------------

    // 航跡ラインの描画用
    /*
    if(d.gpses){
      this.gpses = d.gpses.map(function(g) {
        return new GPS(g.lat, g.lon, g.speed, g.time)
      });

      this.all_lines = d.gpses.map(function(g) {
        return [g.lat, g.lon]
      });
    }
    else{
      this.gpses = []
      this.all_lines = []
    }
    */

    this.gpses = d.gpses ? d.gpses : []
    let l = d.gpses ? parseInt(Number(d.gpses.length/8)): 0;
    this.gpses1 = d.gpses ? d.gpses.slice(0,l) : []
    this.gpses2 = d.gpses ? d.gpses.slice(l+1,l*2)  : []
    this.gpses3 = d.gpses ? d.gpses.slice(l*2+1,l*3)  : []
    this.gpses4 = d.gpses ? d.gpses.slice(l*3+1,l*4)  : []
    this.gpses5 = d.gpses ? d.gpses.slice(l*4+1,l*5)  : []
    this.gpses6 = d.gpses ? d.gpses.slice(l*5+1,l*6)  : []
    this.gpses7 = d.gpses ? d.gpses.slice(l*6+1,l*7)  : []
    this.gpses8 = d.gpses ? d.gpses.slice(l*7+1,l*8)  : []

    this.lines = []
    this.all_lines = []

    //this.max_line_length = 15;//default 15秒
    this.max_line_length = lineLength();//default 15秒

    // 蓄積秒数を考慮した描画ラインの追加
    this.pushGps = function(lat, lon){
      if( Math.abs(lat) < 180 && Math.abs(lon) < 180 ){
        if(this.max_line_length > this.lines.length){
          this.lines.push([lon, lat]);
        }
        else {
          if(this.max_line_length > 0){
            while(this.lines.length > this.max_line_length-1){
              this.lines.shift();
            }
          }
          this.lines.push([lon, lat]);
        }
      }
    }

    // 10秒平均のspeed
    this.speed_10s = 0;
    this.speed_5s = 0;
    this.prev_speed = 0;
    this.speeds = [];
    this.updSpeed = function(speed, speed_10s, speed_5s){

      if (typeof speed === "undefined"){
				//console.log("return!!!")
				return;
			}
      this.prev_speed = this.speed;//前回の値を保持
      this.speed = Number(speed);
      this.speed_10s = Number(speed_10s);
      this.speed_5s = Number(speed_5s);
    }
 
		// 1kmペース 直近1分
    // 60行間の距離の配列
		this.distances_60s = [];
		this.pace1000m = 0;
		this.duration = 0;
		//距離の更新
    this.updateDistance = function(distance){
			if(distance >100)
				return;
			if(60 > this.distances_60s.length){
				this.distances_60s.push(distance);
			}
			else {
				while(this.distances_60s.length > 59){
					this.distances_60s.shift();
				}
				this.distances_60s.push(distance);
			}
			//距離の計算
			let total_distance = 0;
			this.distances_60s.forEach((v,k) => {
					total_distance = total_distance + v;
			});
			if(total_distance < 1)
				this.pace1000m = 0
			else
				this.pace1000m = Math.round(this.distances_60s.length / (total_distance / 1000))
    }


    switch(this.category){
      case 3:
        this.type = "buoy"
        break;
      case 4:
        this.type = "boat"
        break;
      default:
        this.type = "athlete"
        if(!d.athlete_id)//athleteの設定がない
          this.name = d.name
        else if((d.firstname == undefined) && (d.name != ''))
          this.name = d.name;
        else if((d.firstname == undefined) && (d.name == ''))
          this.name = "No-Name";
        else{
          if(this.sail_number != '')
            this.name = this.sail_number+"/"+this.lastname + this.firstname + "(" +this.nation + ")"
          else
            this.name = this.lastname + this.firstname + "(" +this.nation + ")"
        }
        break;
    }

    this.max_time = function(){
      var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
      var i
      for (i = 0; i < this.gpses.length; i++){
        let gps = new Date(this.gpses[i].time)
        if (gps > max_time){
          max_time = gps
        }
      }
      return max_time
    }


    this.min_time = function(){
      var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
      var i
      for (i = 0; i < this.gpses.length; i++){
        let gps = new Date(this.gpses[i].time)
        if(gps < min_time){
          min_time = gps
        }
      }
      return min_time
    }

    //Replayでの位置情報更新
    this.move = function(time){

			//GPSデータ有無のチェック
			if(!this.gpses || this.gpses==[])
				return

			//変数の定義
      this.searchstr = this.searchstr.replace("ONLINE", '');
      this.status = ""
      this.rotate = 0;
      this.speed = 0
			let flg = false;

			//GPSデータの取得
			let gps = getGps(this, time);

			//有効なGPSデータ
			if(gps){
				if(isAthlete(this.category)){
					let distance = calc_distance(gps.lat, gps.lon, this.lat, this.lon);
					this.updateDistance(distance);
          this.rank = gps.rank
				}

				//マーカーの移動
				this.angle = 90 - Math.atan2((-this.lat+gps.lat) , (-this.lon+gps.lon)) * ( 180 / Math.PI ) 
				this.lat = gps.lat;
				this.lon = gps.lon;

				this.pushGps(gps.lat, gps.lon);
				this.speed = gps.speed;

				//calc speed 5s, 10s
				if(5 > this.speeds.length){
						this.speeds.push(gps.speed);
				} else {
						if(this.speeds.length > 0){
							while(this.speeds.length > 10-1){
								this.speeds.shift();
							}
							this.speeds.push(gps.speed);
						}
				}
				if(this.speeds.length > 1){
					let tmp = 0;
					this.speeds.forEach((s, i) => {
						tmp = tmp + Number(s);
					});
					tmp = tmp / this.speeds.length
					this.speed_5s = Math.floor( tmp * Math.pow( 10, 1 ) ) / Math.pow( 10, 1 );
				}



				this.updSpeed(gps.speed, this.speed_5s, this.speed_5s);//10s Speed
				this.time = ('0' + new Date(gps.time).getHours()).slice(-2)
						+ ':' + ('0' + new Date(gps.time).getMinutes()).slice(-2)
						+ ':' + ('0' + new Date(gps.time).getSeconds()).slice(-2)
				this.status = ' '; // GPSデータが存在する場合statusを空白文字とし、DevicelistにONLINEと表示させる
				if ( !this.searchstr.match(/ONLINE/)) {
					this.searchstr += "ONLINE";
				}
				return;
      }else//有効なGPSがない場合
				return;
    }
    //CheckBox
    this.handleInputChange = this.handleInputChange.bind(this);

    //速度のコピー用
		this.copy = function(time, range){
			let out = this.no;//Sail_no

			//-10 to 0 to +10
			for(let t=time-(range*1000); t < time+(range*1000); t+=1000){
				let nt = new Date(t);
				let gps = getGps(this, nt);
				if(gps){
					out = out + "\t" + gps.speed;
				}else{
					out = out + "\t";//no-data
				}
			}

			out = out + "\n";
			return out;
		}

		this.copy60 = function(time, range){
			let out = this.no;//Sail_no
			//-10 to 0 to +10
			for(let t=time; t < time+(range*1000); t+=1000){
				let nt = new Date(t);
				let gps = getGps(this, nt);
				if(gps){
					out = out + "\t" + gps.speed;
				}else{
					out = out + "\t";//no-data
				}
			}
			out = out + "\n";
			return out;
		}


    this.resetMovementData = function(){
      this.lines = [];
      this.all_lines = [];
      this.speeds = [];
      this.distances_60s = [];
    }

		this.getElevation = function(){
			let nt = new Date()
			if(this.lat == 0)
				return;
			if(nt - this.last_fetch > 10000){
				this.last_fetch = nt;
				axios.get(`https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${this.lon},${this.lat}.json?access_token=pk.eyJ1IjoiaTAxMjEwbWwiLCJhIjoiY2t1NWZ0ZGNhNHp1eTJ2cDMxNXV0OHAwNCJ9.SqRPyXL7O65p-osCqqmp4Q` )
				.then((response) => {
					let f = response.data.features;
					for( let i=0; i < f.length; i++ ){
						let ele = f[i].properties.ele
						if( ele != undefined ){
							this.elevation = ele;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
				}else{
					//console.log("wait 10 sec")
				}
		}

  }//end constructor

  handleInputChange(event){
    this.ischecked = !this.ischecked;
  }
}

class GPS{
  constructor(lat, lon, speed, time){
    this.lat = lat
    this.lon = lon
    this.speed = speed
    this.time = new Date(time)
  }
}

function getGps(d, time){
	//探索木による探索
	var tmp=[];
	if(d.gpses8.length>0 ? new Date(d.gpses8[0].time) <= time : false)
		tmp = d.gpses8;
	else if(d.gpses7.length>0 ? new Date(d.gpses7[0].time) <= time : false)
		tmp = d.gpses7;
	else if(d.gpses6.length>0 ? new Date(d.gpses6[0].time) <= time : false)
		tmp = d.gpses6;
	else if(d.gpses5.length>0 ? new Date(d.gpses5[0].time) <= time : false)
		tmp = d.gpses5;
	else if(d.gpses4.length>0 ? new Date(d.gpses4[0].time) <= time : false)
		tmp = d.gpses4;
	else if(d.gpses3.length>0 ? new Date(d.gpses3[0].time) <= time : false)
		tmp = d.gpses3;
	else if(d.gpses2.length>0 ? new Date(d.gpses2[0].time) <= time : false)
		tmp = d.gpses2;
	else
		tmp = d.gpses1;

	for (let i=0; i < tmp.length - 1; i++){
		var gps = tmp[i]
		var next = tmp[i+1]

		//対象のデータが存在しない場合は処理しない
		if(new Date(gps.time) > time)
			return null;

		if ( (new Date(gps.time) <= time) && (time <= new Date(next.time)) ){
			return gps;
		}
	}

	return null;
}


