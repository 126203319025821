import { useState, useEffect } from "react";

const initialRoutes = {
  Swim: { exists: false , visible: true, color: "" },
  Bike: { exists: false , visible: true, color: "" },
  Run: { exists: false , visible: true, color: "" },
};

const useRouteVisibility = (courseGeoJSON) => {
  const [visibleRoutes, setVisibleRoutes] = useState(initialRoutes);

  useEffect(() => {
    if (!courseGeoJSON || !courseGeoJSON.features) return;

    let updatedVisibility = { ...initialRoutes };

    try {
        courseGeoJSON.features.forEach((feature) => {
            const comment = feature.properties.comment;
            const color = feature.properties.color;
        
            if (comment === "Swim" || comment === "Bike" || comment === "Run") {
                updatedVisibility[comment] = {
                ...updatedVisibility[comment],
                exists: true,
                color: color,
                };
            }
            });
    } catch (error) {
        console.error("Failed to parse course data: ", error);
    }

    setVisibleRoutes(updatedVisibility);

  }, [courseGeoJSON]);

  return [visibleRoutes, setVisibleRoutes];
};

export default useRouteVisibility;
