import * as React from 'react';

function Online(props) {

  const {online} = props;

  return (
    online ? (
			online.wwc ? <></> : 
      online.online ?  <p className={"online"}> ONLINE </p> : <p className={"online"}> REPLAY </p>
    ) : (
      <p className={"offline"}> OFFLINE </p>
    )
  );
}

export default React.memo(Online);
