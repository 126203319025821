import React from 'react';
import { ClockLoader } from 'react-spinners';

const Spinner = (props) => {
  const {loading} = props;
  return (
    <>
    {loading && (
      <div className="loader-overlay">
        <ClockLoader size={50} color={"#ffffff"} loading={loading} />
     </div>
    )}
    </>
  );
};

export default Spinner;
