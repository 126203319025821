import React from "react";

import PanelContent from "./panel_content";

function PanelContentCheckpoint(props) {
  const {showCheckpoint, setShowCheckpoint} = props;

  return (
    <PanelContent title="チェックポイントの表示">
			<div className={"switch"}>
				<label className={"switch__label"}>
					<input type="checkbox" className={"switch__input"} checked={showCheckpoint} onChange={()=>setShowCheckpoint(!showCheckpoint)}/>
					<span className={"switch__content"}></span>
					<span className={"switch__circle"}></span>
				</label>
			</div>
    </PanelContent>
  );
}

export default PanelContentCheckpoint;
