import * as React from 'react';
import {useState, useEffect} from 'react';
import {Marker, Popup} from 'react-map-gl';


//const ICON = 'M11.2 1.5L11.81 2.12L12.39 3.23L12.99 3.92L13.2 5.22L13.51 6.2L13.7 7.37L13.76 9.14L13.7 10.69L13.76 11.76L13.57 13.06L13.32 14.37L12.99 15.67L12.76 16.7L12.26 18.02L11.45 19.59L10.76 20.9L8.07 20.9L7.32 19.59L6.64 18.02L6.14 16.7L5.91 15.87L5.57 14.37L5.32 13.06L5.14 11.76L5.2 10.62L5.2 9.14L5.26 7.17L5.45 6.13L5.91 5.22L6.07 3.92L6.64 3.3L7.26 2.05L7.7 1.31L8.27 0.67L8.7 0L9.45 0'

const a="M35.938,171.641H12.734C5.703,171.641,0,177.344,0,184.375v96.578c0,7.031,5.703,12.734,12.734,12.734h23.203 c7.031,0,12.734-5.703,12.734-12.734v-96.578C48.672,177.344,42.969,171.641,35.938,171.641z"
const b="M164.266,287.438c-31.703,0-57.484,25.781-57.484,57.484c0,31.688,25.781,57.469,57.484,57.469 s57.469-25.781,57.469-57.469C221.734,313.219,195.969,287.438,164.266,287.438z M164.266,371.406 c-14.625,0-26.484-11.859-26.484-26.484c0-14.641,11.859-26.484,26.484-26.484c14.609,0,26.484,11.844,26.484,26.484 C190.75,359.547,178.875,371.406,164.266,371.406z"
const c="M394.969,287.438c-31.703,0-57.484,25.781-57.484,57.484c0,31.688,25.781,57.469,57.484,57.469 c31.688,0,57.469-25.781,57.469-57.469C452.438,313.219,426.656,287.438,394.969,287.438z M394.969,371.406 c-14.625,0-26.5-11.859-26.5-26.484c0-14.641,11.875-26.484,26.5-26.484s26.469,11.844,26.469,26.484 C421.438,359.547,409.594,371.406,394.969,371.406z"
const d="M394.969,335.844c-5.031,0-9.094,4.063-9.094,9.078s4.063,9.078,9.094,9.078c5,0,9.078-4.063,9.078-9.078 S399.969,335.844,394.969,335.844z"
const e="M488.594,200.234l-81.953-15.859l-75.313-57.141c-15.078-11.438-33.484-17.625-52.406-17.625H100.234 c-21.281,0-38.547,17.25-38.547,38.531v169.906c0,10.641,8.641,19.266,19.281,19.266h15.156 c3.797-34.25,32.906-60.969,68.141-60.969s64.344,26.719,68.141,60.969h75.688h18.719c3.813-34.25,32.906-60.969,68.156-60.969 c35.234,0,64.344,26.719,68.125,60.969h29.641c10.641,0,19.266-8.625,19.266-19.266v-89.438 C512,214.766,502.188,202.859,488.594,200.234z M86.906,191.172c-2.656,0-4.813-2.156-4.813-4.813v-38.219 c0-10,8.141-18.141,18.141-18.141h106.469v61.172H86.906z M317.625,235.953h-27c-1.938,0-3.5-1.563-3.5-3.5 c0-1.922,1.563-3.5,3.5-3.5h27c1.938,0,3.5,1.578,3.5,3.5C321.125,234.391,319.563,235.953,317.625,235.953z M377,189.531 c-0.344,0.984-1.25,1.641-2.281,1.641H222.563V130h56.359c14.391,0,28.609,4.781,40.078,13.469l57.156,43.375 C377,187.469,377.313,188.563,377,189.531z"

const SIZE = 3;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function AnimationCar(props) {
  const {position, name, speed, time, color, duration} = props;
  const X = 0; 
  const Y = 5; 
  const [pos, setPos]   = useState({lat:0, lon:0, angle:0});
  const [info, setInfo] = useState(false);
  const [diff, setDiff] = useState({x:X, y:Y});
  const [prevtime, setPrevtime]   = useState(null);

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    //更新がなければ処理しない
    if( time == prevtime ){
      //console.log("same time.")
      return;
    } else{
      setPrevtime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  pos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }

      let v = {
        lat:   (position.lat   - pos.lat),
        lon:   (position.lon   - pos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: from.angle + v.angle * d
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setPos, v, Date.now(), pos, 0));
    }
  },[position])

  // Tooltipの表示位置
  useEffect(() => {
    if(info){// hide tooltip
      setDiff({x:X, y:Y-0.518});
    } else {// show tooltip
      setDiff({x:X, y:Y});
    }
  },[info])


  let dir = 1;
  let dir2 = 90;
  let tmp = pos.angle;
  if(tmp<0)
    tmp = 360+pos.angle;
  if(tmp > 180){
    dir = -1;
    dir2 = -90;
  }

  return(
    <>
    <Marker longitude={pos.lon} latitude={pos.lat}>

      <div className="tooltip" onClick={()=>(setInfo(!info))} >{name}
        {info && (
          <>
            <div className="tooltip-speed">SPD:{speed}km/h</div>
            <div className="tooltip-time">UPD:{time}</div>
          </>
        )}
      </div>

      <svg
        height={SIZE}
        viewBox="200 50 60 60"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          transform: `translate(0px,-40px) rotate(0deg) scale(${dir},1)`,
          overflow: 'visible'
        }}
        onClick={() => (setInfo(!info))}
      >
        <path d={a} fill={color}/>
        <path d={b} fill={color}/>
        <path d={c} fill={color}/>
        <path d={d} fill={color}/>
        <path d={e} fill={color}/>
        <circle cx={"164.266"} cy={"344.922"} r={"9.078"} fill={color}/>
      </svg>
    </Marker>
    </>
  );
}

export default React.memo(AnimationCar);
