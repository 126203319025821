import React, { Component } from 'react';
import { copyToClipboard} from '../../helpers';
import { isAthlete } from '../../initialize/device_helpers.js';

//コピーボタンハンドラ
//横軸:時間
//縦軸:選手
export function handleCopySpeeds(devices, currentReplayTimeSec) {
	let out="Sail_no";
	//Header
	const range = 20;//表示する秒数
	for(let t=-range; t < range; t+=1){
		out = out + "\t" + t;
	}
	out = out + "\n";
	//Body
	[...devices].map(([key, d]) => {
		if(isAthlete(d.category)){
			out = out + d.copy(currentReplayTimeSec*1000, range);
		}
	});
	//クリップボードにコピー
	copyToClipboard(out);
}

export function handleCopySpeeds60(devices, currentReplayTimeSec) {
	let out="Sail_no";
	//Header
	const range = 60;//表示する秒数
	for(let t=0; t < range; t+=1){
		out = out + "\t" + t;
	}
	out = out + "\n";
	//Body
	[...devices].map(([key, d]) => {
		if(isAthlete(d.category)){
			out = out + d.copy60(currentReplayTimeSec*1000, range);
		}
	});

	//クリップボードにコピー
	copyToClipboard(out);
}


