import * as React from 'react';
import WindDirectionArrow from './wind-direction-arrow.svg';

function WindInfo(props) {
  const {wind, bearing} = props;
  if(!wind)
    return null;
  return (
    <div className="wind_panel">
      <div className='wind-direction-icon'>
        <span className='top-label'>WIND</span>
        <span className='arrow-label'>
          <img alt={'Wind'} className='arrow-img' src={WindDirectionArrow} style={{ transform: `rotateZ(${wind.wind_direction - bearing}deg)` }} />
        </span>
        <span className='bottom-label wind-unit'>{wind.max_wind} knot</span>
      </div>
    </div>
  );
}

export default React.memo(WindInfo);


