export const scenery_icon_sources = [
    {
      id: 'akarenga',
      lat: 35.452422,
      long: 139.642818,
      imagePath: '/scenery_icons/akarengasouko.png'
    },
    {
      id: 'chukagai',
      lat: 35.443930,
      long: 139.647791,
      imagePath: '/scenery_icons/chukagai.png'
    },
    {
      id: 'hikawamaru',
      lat: 35.44670222357404,
      long: 139.6512127977798,
      imagePath: '/scenery_icons/hikawamaru_new.png'
    },

   {
      id: 'kaikoukinenkaikan',
      lat: 35.447573,
      long: 139.640972,
      imagePath: '/scenery_icons/kaikoukinenkaikan.png'
    },
    {
      id: 'kanagawa-kenchou',
      lat: 35.447774520564934,
      long: 139.6426413116279,
      imagePath: '/scenery_icons/kanagawa-kenchou.png'
    },
    {
      id: 'kappunudle-museum',
      lat: 35.455307,
      long: 139.639207,
      imagePath: '/scenery_icons/kappunudle-museum.png'
    },
    {
      id: 'kosumoworld',
      lat: 35.455322167783784,
      long: 139.63705139995074,
      imagePath: '/scenery_icons/kosumoworld.png'
    },
    {
      id: 'marinetower',
      //lat: 35.444085,
      //long: 139.65056,
      lat: 35.44444987169929,
      long: 139.65129156705125,
      imagePath: '/scenery_icons/marinetower.png'
    },
    {
      id: 'mizunoshugoshin',
      lat: 35.445704,
      long: 139.6502,
      imagePath: '/scenery_icons/mizunoshugoshin.png'
    },
    {
      id: 'ningyounoie',
      lat: 35.44325251970912,
      long: 139.6523975012652,
      imagePath: '/scenery_icons/ningyounoie.png'
    },
    {
      id: 'pasifico-yokohama',
      lat: 35.45828,
      long: 139.6370,
      imagePath: '/scenery_icons/pasifico-yokohama.png'
    },
    {
      id: 'worldporters',
      lat: 35.45412770104305,
      long: 139.63863196858887,
      imagePath: '/scenery_icons/worldporters.png'
    },
    {
      id: 'yokohama-yusenbill',
      lat: 35.45045420760681,
      long: 139.63947388247544,
      imagePath: '/scenery_icons/yokohama-yusenbill.png'
    },
    {
      id: 'yokohama-zeikan',
      lat: 35.449325,
      long: 139.642368,
      imagePath: '/scenery_icons/yokohama-zeikan.png'
    },
    {
      id: 'yookohama-kaikousiryoukan',
      lat: 35.44751832379827,
      long: 139.6437998371816,
      imagePath: '/scenery_icons/yookohama-kaikousiryoukan.png'
    },
];
