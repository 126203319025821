import * as React from 'react';
import {useState, useEffect} from 'react';
import { Marker } from 'react-map-gl';
import IconsCamera from './icon_camera';

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function AnimationCamera(props) {
  const {position, name, focus, speed, time, color, duration, animate_flg} = props;
  const [currentPos, setCurrentPos]   = useState({lat:0, lon:0, angle:0});
  const [info, setInfo] = useState(false);
  const [prevtime, setPrevtime]   = useState(null);

  //Duration
  let _duration = 1000 // initial value
  if(duration)
    _duration = duration

  //positionが更新のたびに起動
  useEffect(() => {

    //更新がなければ処理しない
    if( time == prevtime ){
      //console.log("same time.")
      return;
    } else{
      setPrevtime( time );
    }

    //デバイスを移動
    if((Math.abs(position.lat) < 180 )){
      //パラメタ
      //移動距離
      let d_angle = position.angle -  currentPos.angle;

      if(Math.abs(d_angle) > 180){
        if(d_angle > 0){
          d_angle = d_angle - 360;
        } else {
          d_angle = d_angle + 360;
        }
      }


      let v = {
        lat:   (position.lat   - currentPos.lat),
        lon:   (position.lon   - currentPos.lon),
        angle: ( d_angle ),
      }
      // 移動だけ
      var move = function(setPos, v, prev, from, sumtime){
        let now = Date.now()
        let dt = now  - prev;
        sumtime = sumtime + dt;

        // end animation
        if(sumtime > _duration){
          return;
        }

        let d = dt/_duration;
				let a = from.angle + v.angle * d;
        let p = {
          lat:   from.lat + v.lat * d,
          lon:   from.lon + v.lon * d,
          angle: isNaN(a)? 0 : a
        }
        //動かす
        setPos(p);

        //Animation描画
        requestAnimationFrame(()=>move(setPos, v, now, p, sumtime));
      }

      //Animation描画
      requestAnimationFrame(()=>move(setCurrentPos, v, Date.now(), currentPos, 0));
    }
  },[animate_flg])

  return(
    <>
    <Marker longitude={currentPos.lon} latitude={currentPos.lat}>

      <div className="tooltip" onClick={()=>(setInfo(!info))} >{name}
        {info && (
          <>
            <div className="tooltip-speed">SPD:{speed}km/h</div>
            <div className="tooltip-time">UPD:{time}</div>
          </>
        )}
      </div>

      <IconsCamera color={color} offsetY={-18} focus={focus}/>

    </Marker>
    </>
  );
}

export default React.memo(AnimationCamera);
