import React from 'react';
import { Marker} from 'react-map-gl';

const SIZE = 40;

const START_GOAL_SVG = <svg height={SIZE} viewBox="0 0 20 22" fill="none">
                        <path d="M1.75 20.75V2.19406C1.75023 2.12846 1.76767 2.06407 1.80057 2.00731C1.83347 1.95056 1.88069 1.90343 1.9375 1.87063C2.30359 1.65594 3.29312 1.25 5.5 1.25C8.5 1.25 12.2969 3.5 14.5 3.5C15.7439 3.49677 16.9746 3.24546 18.1202 2.76078C18.1344 2.75483 18.1499 2.7525 18.1653 2.75399C18.1807 2.75549 18.1955 2.76076 18.2083 2.76934C18.2212 2.77793 18.2317 2.78955 18.239 2.80319C18.2462 2.81682 18.25 2.83205 18.25 2.8475V13.13C18.2499 13.1663 18.2393 13.2018 18.2194 13.2322C18.1995 13.2625 18.1712 13.2865 18.138 13.3011C17.7517 13.4703 16.3905 14 14.5 14C12.25 14 8.5 12.5 5.5 12.5C2.5 12.5 1.75 13.25 1.75 13.25" fill="#60C2E2"/>
                        <path d="M1.75 20.75V2.19406C1.75023 2.12846 1.76767 2.06407 1.80057 2.00731C1.83347 1.95056 1.88069 1.90343 1.9375 1.87063C2.30359 1.65594 3.29312 1.25 5.5 1.25C8.5 1.25 12.2969 3.5 14.5 3.5C15.7439 3.49677 16.9746 3.24546 18.1202 2.76078C18.1344 2.75483 18.1499 2.7525 18.1653 2.75399C18.1807 2.75549 18.1955 2.76076 18.2083 2.76934C18.2212 2.77793 18.2317 2.78955 18.239 2.80319C18.2462 2.81682 18.25 2.83205 18.25 2.8475V13.13C18.2499 13.1663 18.2393 13.2018 18.2194 13.2322C18.1995 13.2625 18.1712 13.2865 18.138 13.3011C17.7517 13.4703 16.3905 14 14.5 14C12.25 14 8.5 12.5 5.5 12.5C2.5 12.5 1.75 13.25 1.75 13.25" stroke="#323438" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                      </svg>;
const GOAL_FLAG_SVG = <svg height={SIZE} viewBox="0 0 20 22" fill="none">
                        <path d="M1.75 20.75V2.19406C1.75023 2.12846 1.76767 2.06407 1.80057 2.00731C1.83347 1.95056 1.88069 1.90343 1.9375 1.87063C2.30359 1.65594 3.29312 1.25 5.5 1.25C8.5 1.25 12.2969 3.5 14.5 3.5C15.7439 3.49677 16.9746 3.24546 18.1202 2.76078C18.1344 2.75483 18.1499 2.7525 18.1653 2.75399C18.1807 2.75549 18.1955 2.76076 18.2083 2.76934C18.2212 2.77793 18.2317 2.78955 18.239 2.80319C18.2462 2.81682 18.25 2.83205 18.25 2.8475V13.13C18.2499 13.1663 18.2393 13.2018 18.2194 13.2322C18.1995 13.2625 18.1712 13.2865 18.138 13.3011C17.7517 13.4703 16.3905 14 14.5 14C12.25 14 8.5 12.5 5.5 12.5C2.5 12.5 1.75 13.25 1.75 13.25" fill="#E82A29"/>
                        <path d="M1.75 20.75V2.19406C1.75023 2.12846 1.76767 2.06407 1.80057 2.00731C1.83347 1.95056 1.88069 1.90343 1.9375 1.87063C2.30359 1.65594 3.29312 1.25 5.5 1.25C8.5 1.25 12.2969 3.5 14.5 3.5C15.7439 3.49677 16.9746 3.24546 18.1202 2.76078C18.1344 2.75483 18.1499 2.7525 18.1653 2.75399C18.1807 2.75549 18.1955 2.76076 18.2083 2.76934C18.2212 2.77793 18.2317 2.78955 18.239 2.80319C18.2462 2.81682 18.25 2.83205 18.25 2.8475V13.13C18.2499 13.1663 18.2393 13.2018 18.2194 13.2322C18.1995 13.2625 18.1712 13.2865 18.138 13.3011C17.7517 13.4703 16.3905 14 14.5 14C12.25 14 8.5 12.5 5.5 12.5C2.5 12.5 1.75 13.25 1.75 13.25" stroke="#323438" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                      </svg>;

function StartGoalFlag(props){
  const {startTwoCoords, goalTwoCoords} = props;

  return (
    <>
      {startTwoCoords &&
        <Flag coords={getCenterCoord(startTwoCoords.coord1, startTwoCoords.coord2)}
          svg={START_GOAL_SVG}/>}
      {goalTwoCoords && 
        <Flag coords={getCenterCoord(goalTwoCoords.coord1, goalTwoCoords.coord2)}
          svg={GOAL_FLAG_SVG}/>}
    </>
  );
}

function getCenterCoord(coord1, coord2){

  const centerLat = (Number(coord1.latitude) + Number(coord2.latitude)) / 2;
  const centerLon = (Number(coord1.longitude) + Number(coord2.longitude)) / 2;
  return {latitude: centerLat, longitude: centerLon};
}

function Flag(props){
  const {coords, svg} = props;

  return (
    <Marker
      latitude={coords.latitude}
      longitude={coords.longitude}
      offsetTop={-SIZE}
    >
      {svg}
    </Marker>
  );
}

export default React.memo(StartGoalFlag);
