import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Dot } from 'recharts';
import {hidden_tooltip} from '../../helpers';

const ElevationChart = ({ data, activeIndexes, color, youtube }) => {

  if (!data || !activeIndexes) {
    return <></>;
  }

  const processedData = data.map((point, index) => ({
    name: `Point ${index + 1}`,
    elevation: point.elevation,
  }));

  // カスタムドットコンポーネント
  const CustomDot = (props) => {
    const { cx, cy, index } = props;
    const activeIndex = activeIndexes.find(ai => ai.index === index);
    if (activeIndex) {
      return (
        <Dot cx={cx} cy={cy} r={5} fill={activeIndex.color} />
      );
    }
    return null; // 他のドットは表示しない
  };

  // 最大のインデックスを取得
  const maxIndex = Math.max(...activeIndexes.map(item => item.index));
  const maxIndexItem = activeIndexes.find(item => item.index === maxIndex);

  return (
    <ResponsiveContainer width="105%" height={window.innerWidth < 500 ? 100 : youtube?'30%':'20%'} style={{ marginBottom: '0px', marginLeft: '-5%'}}>
      <LineChart
        data={data}
        style={{ backgroundColor: hidden_tooltip() ? '#00ff00' : '#000000' }}
      >
        <CartesianGrid 
          strokeDasharray="3 3" 
          stroke="#ffffff" 
          vertical={false} // 縦ラインを非表示
          horizontal={false} // 水平線（等高線）を表示
        />
        <XAxis 
          dataKey={(entry, index) => index}
          //tick={{ fill: '#ffffff' }} // X軸の目盛りの文字色を白に設定
          tick={false} // X軸の目盛りを非表示に設定
          axisLine={{ stroke: '#ffffff' }} // X軸の線の色を白に設定
        />
        <YAxis 
          dataKey="elevation"
          //tick={{ fill: '#ffffff' }} // Y軸の目盛りの文字色を白に設定
          tick={false} // X軸の目盛りを非表示に設定
          axisLine={{ stroke: '#ffffff' }} // Y軸の線の色を白に設定
        />
        <Tooltip 
          contentStyle={{ backgroundColor: '#333333', color: '#ffffff' }} // ツールチップの背景色と文字色を設定
          itemStyle={{ color: '#ffffff' }} // ツールチップ内のアイテムの文字色を白に設定
          formatter={(value, name, props) => [`${value} m`, 'elevation']} // 標高値に [m] を追加して表示
        />

        {/* 標高のライン */}
        <Line
          type="monotone"
          dataKey="elevation"
          stroke={color}
          dot={<CustomDot />} // カスタムドットを使用
          activeDot={false} // activeDot は無効化
          isAnimationActive={false}
          strokeWidth={5}
        />

        {/* 最大インデックスの位置に垂直ラインを表示 */}
        {maxIndexItem && (
          <ReferenceLine
            x={maxIndexItem.index}
            stroke={maxIndexItem.color}
            strokeDasharray="3 3"
            label={{ 
              position: 'bottom', 
              value: `ele:${data[maxIndexItem.index]?.elevation} m`,
              fill: hidden_tooltip() ? '#00ff00' : '#ffffff' // ラベルの文字色を白に設定
            }}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ElevationChart;

