import * as React from 'react';
function PanelContent(props) {
  const {title, children } = props;

  return (
    <>
      <div className="panel-container">
        <div className="menu-item">
          <span className="menu-item-title">{title}</span>
          <div className="panel-content">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default PanelContent;
