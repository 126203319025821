import React, { useEffect } from "react";

const TeamName = ({ name, onHeight = () => {} }) => {
  const height = 50;

  useEffect(() => {
    if (!name) {
      return;
    }
    onHeight(height);
  }, [onHeight, name]);

  return (
    <>
      {name ? (
        <div style={{ backgroundColor: "white", display: "flex", alignItems: "center", height: height }}>
          <img src="/icon_interface/hawkcast.jpg" alt="logo" style={{height: "33px", width: "22px", marginLeft: "20px"}} />
          <div style={{marginLeft: "16px"}}>{name}</div>
        </div>
      ) : <></>}
    </>
  );
};

export default React.memo(TeamName);
