import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect, useCallback} from 'react';
import {init, updateHeatTime, getEleOffset, URLParams} from '../../initialize/initialize.js';
import Speeds from '../speed/speeds';
import Speedometer from '../speed/speed_meter';

//CSS
import '../../index.css';


export default function BikeSpeed(props) {

  const [devices, setDevices] = useState([]);
  const [center, setCenter] = React.useState([35.28167445342043, 139.67306039427112]);
  const [online, setOnline] = useState();
  const [wind, setWind] = useState(null);
  const [course, setCourse] = useState(null);
  const [heat, setHeat] = useState(null);
  const [heatName, setHeatName] = useState(null);
  const [heatTime, setHeatTime] = useState(null);
  const [heatStatus, setHeatStatus] = useState(null);


	useEffect(() => {
		init( setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName);
		//updateHeatTime(setHeatTime, setHeatStatus);
	},[]);

  const bikes = [];

	let max = 70;

  devices.forEach((device, i) => {
      let head = "speed-head";
      let data = "speed-data";
      bikes.push(
        <div key={device.id} style={{ padding: '20px' }}>
          <span key={"s"+device.id} className={head} style={{ fontSize: '64px', paddingRight: '200px' }}> {device.disp_name} </span>
          <span key={"d"+device.id} className={data} style={{ fontSize: '64px' }}> {String(device.speed_5s).padStart(2, '0')} km/h </span>


        </div>
      )
  });


	const bike_speed = (
    <>
      <div className={'bike-speed-container'}>
				{bikes}
			</div>
    </>
	)

  return (
    <>
      {bike_speed}
    </>
  );
}
