import * as React from 'react';
import { Marker } from 'react-map-gl';
import { scenery_icon_sources } from './scenery_icon_sources';

function SceneryIcons(props){
  const {zoomLevel, viewBearing} = props;

    return <>
            {scenery_icon_sources.map((icon) => {
              return( <SceneryIcon
                        {...icon}
                        zoomLevel={zoomLevel}
                        viewBearing={viewBearing}
                      />)
            })}
          </>;
}

function SceneryIcon(props){
  const {lat, long, imagePath, zoomLevel, viewBearing} = props;

  const iconSize = getIconSize(zoomLevel);

  const initialBearing = 34;
  const bearing = initialBearing - viewBearing;

  return <Marker
            latitude={lat}
            longitude={long}
            offsetTop={-iconSize / 2}
            offsetLeft={-iconSize / 2}
          >
            <img src={imagePath} alt="icon" style={{width: `${iconSize}px`, height: `${iconSize}px`, transform: `rotate(${bearing}deg)`}}/>
          </Marker>;
}

// zoomLevelに応じたアイコンサイズの計算
// 初期値や条件式は微調整によるもの
function getIconSize(zoomLevel) {

	let k = zoomLevel - 15.088623183975633;

	let iconSize = 35;

	if(k < -1.4){
		} else {
			if(k < -1){
			let l = Math.floor(Math.abs(k))-Math.abs(k);
			k = 1 + l;
		} else if(k < 0){
			k = 1;
		} else if (k < 1){
			k = 1 + k;
		} else{
			k = k * 2;
		}
		iconSize = zoomLevel * 5 * k;
	}

  return iconSize;
}

export default SceneryIcons;
