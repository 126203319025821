import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect, useCallback} from 'react';
import {render} from 'react-dom';
import API from '../../initialize/API.js';
import {init, is_mode, is_triathlon_view, is_individual_view, is_terrain_view, is_wwc, getReplayDataUrl, updateHeatTime, getEleOffset, URLParams} from '../../initialize/initialize.js';

//MUI
import Box from '@mui/material/Box';

//CSS
import '../../index.css';


const urlParams = new URLSearchParams(window.location.search);


//Template
function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

var g_devices = []

// App 本体
export default function Ranking() {

	var intervalRanking;
  const [rankings, setRankings] = useState([]);
  const [devices, setDevices] = useState([]);
  const [center, setCenter] = React.useState([35.28167445342043, 139.67306039427112]);
  const [online, setOnline] = useState();
  const [wind, setWind] = useState(null);
  const [course, setCourse] = useState(null);
  const [heat, setHeat] = useState(null);
  const [heatName, setHeatName] = useState(null);

	//Initialize Heat
	useEffect(() => {
		if(is_mode() == "live"){
			init( setDevices, setCenter, setOnline, setWind, setCourse, setHeat, setHeatName);
		}
	}, []);

	useEffect(() => {
		g_devices = devices
	}, [devices]);


	//ランキングリストを一定周期で取得する
  useEffect(() => {
		clearInterval( intervalRanking );
		intervalRanking =  setInterval(() => {
			let onSuccess = (res) => {
				if(!res.data)
					return
				if(!res.data.Ranklist)
					return

				let out = []
				let max_id=0;
				let max_spd=0;

				//max speed search
				g_devices.forEach( d => {
					if(d.category == 1){
						if(d.speed_5s > max_spd){
							max_id = d.id;
							max_spd = d.speed_5s;
						}
					}
				})

				res.data.Ranklist.forEach( r => {
					g_devices.forEach( d => {
						if(d.athlete_id == r.athlete_id){
							let row = {
								"id": d.id,
								"is_max": max_id==d.id ? true : false,
								"rank": r.rank,
								"sailno": d.no,
								"nation": d.nation,
								"speed_5s": d.speed_5s,
								"fwd_dist": r.fwd_dist,
								"nation": d.nation,
								"is_goal": r.is_goal,
							}
							out.push( row )
						}
					})
				})

				setRankings( out );
			}

			let heat_id   = urlParams.get("heat_id")
			let rank_host = process.env.REACT_APP_RANKING_URL;
			API.getRankingWithHeat({ onSuccess: onSuccess, host: rank_host,  heat_id: heat_id })
		} ,1000);
	},[]);


	if(!rankings || rankings.length == 0)
		return <>no data.</>


	const goal = (
		<img className={"goal-flag"} src={"/goal_flag.png"}  alt={"goal"} />
	)

	const ranking_list = (
    <Box
      sx={{
				bgcolor: '#228b22',
        width: '260px',
        //height: '140px',
        color: '#fff',
        '& > .MuiBox-root > .MuiBox-root': {
          p: 0.5,
          borderRadius: 1,
          fontSize: '1.0rem',
        },
      }}
    >

      <Box
        sx={{
					bgcolor: 'rgba(255, 255, 255, 1)',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 2fr 2fr',
          gap: '1px',
          m: 1,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"rank nation sailno speed"`,
          fontWeight: '700',
					alignItems: 'center', // 上下中央寄せ
        }}
      >
        <Box sx={{ gridArea: 'rank' , color: '#000'  }}><span style={{}}></span></Box>
        <Box sx={{ gridArea: 'nation' , color: '#000'  }}><span style={{}}></span></Box>
        <Box sx={{ gridArea: 'sailno' , color: '#000'  }}>Sail#</Box>
        <Box sx={{ gridArea: 'speed' , color: '#000'}}>Spd<span style={{ fontSize: '8px' }}>(km/h)</span></Box>
      </Box>

			{
			rankings.map( (r) => (
				<Box
					sx={{
						bgcolor: 'rgba(5, 5, 5, 0.7)',
						display: 'grid',
						gridTemplateColumns: '1fr 1fr 2fr 2fr',
						gap: '1px',
						m:  1,
						mt: 2,
						mb: 2,
						gridTemplateRows: 'auto',
						gridTemplateAreas: `"rank nation sailno speed"`,
          	fontWeight: '800',
					  alignItems: 'center', // 上下中央寄せ
						height: '40px', // ボックスの高さを指定
					}}
				>
					<Box sx={{ gridArea: 'rank'  , color: '#FFF', whiteSpace: 'nowrap', textAlign: 'center' }} className={''}>{r.rank}</Box>
					<Box sx={{ gridArea: 'nation', color: '#FFF',display: 'flex',alignItems: 'left'}}><img className={"nation-flag"} src={"/NationalFlag/" + r.nation.toLowerCase()+".png"}  alt={r.nation} /></Box>
					<Box sx={{ gridArea: 'sailno', color: '#FFF', whiteSpace: 'nowrap', textAlign: 'center'  }}>{ r.sailno }</Box>
					<Box sx={{ gridArea: 'speed' , color: '#FFF', textAlign: 'center' }}><span style={{ color: r.is_max? 'rgba(255, 127, 80, 1)': '#FFF' }}>{ r.is_goal ? goal : r.speed_5s }</span></Box>
				</Box>
			))
			}

    </Box>
	)


	const header = (
		<>
    <div className={'ax-on'}></div>
		<div className={'race-head'} style={{ display: "flex", alignItems: "center" }}>
			<img src="/icon_interface/crewing_mark.png" alt="logo" style={{height: "33px", width: "22px", marginLeft: "20px"}} />
			<div className={"race-name"} > {heat.discipline}　{heat.name}</div>
		</div>
		</>
	)

					//<Box sx={{ gridArea: 'speed' , color: '#fff'}}>{ r.speed_5s }</Box>
	const disp_desktop = (
    <>
      {header}
      {ranking_list}
    </>
	)

  return (
    <>
			<div style={{ backgroundColor: '#00ff00', minHeight: '100vh' }}>
			{ disp_desktop }
			</div>
    </>
  );
}

// <Box sx={{ gridArea: 'rank', color: '#fff', textAlign: 'center'  }}>&nbsp; &nbsp;{ r.rank }</Box>
