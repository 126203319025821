import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import {is_mode} from '../../initialize/initialize.js';
import API from '../../initialize/API.js';

//rankingリスト用
var g_ranks = [];
var g_devices = []
let max_lap = 0;

export default function SidePanelRanking( props ) {
  const { devices, heat, handleChangeSelectedDevice, setRanking, course, setWindLine } = props;
  const [rankings, setRankings] = useState([]);
	let intervalRanking= React.useRef();

	useEffect(() => {
		g_devices = devices

		//ischeckedの更新用
		let out = []
		rankings.forEach( r => {
			g_devices.forEach( d => {
				if(d.id == r.id){
					r.ischecked = d.ischecked
					r.showMarker = d.showMarker
          r.speed_5s = d.speed_5s
					out.push( r )
				}
			})
		})
		setRankings( out );
    g_ranks = out;

	},[devices]);


	//ランキングリストを一定周期で取得する
	useEffect(() => {
		if(is_mode() == "live" && heat){
		clearInterval( intervalRanking.current );
		intervalRanking =  setInterval(() => {
			let onSuccess = (res) => {
				if(!res.data)
					return
				if(!res.data.Ranklist)
					return
				let out = []
				let max_id=0;
				let max_spd=0;
				//max speed search
				g_devices.forEach( d => {
					if(d.category == 1){
						if(d.speed_5s > max_spd){
							max_id = d.id;
							max_spd = d.speed_5s;
						}
					}
				})
				res.data.Ranklist.forEach( r => {
					g_devices.forEach( d => {
						if(d.athlete_id == r.athlete_id){
							let row = {
								"id": d.id,
								"is_max": max_id==d.id ? true : false,
								"showMarker": d.showMarker,
								"rank": r.rank,
								"sailno": d.no,
								"nation": d.nation,
								"firstname": d.firstname,
								"club": d.club,
								"speed_5s": d.speed_5s,
								"fwd_dist": r.fwd_dist,
								"nation": d.nation,
								"is_goal": r.is_goal,
								"name": d.fullname,
								"ischecked": d.ischecked,
								"lap": r.lap,
								"color_code": d.color_code,
							}
							out.push( row )
						}
					})
				})
				setRankings( out );
				setRanking( out );
        g_ranks = out;
			}
			let rank_host = process.env.REACT_APP_RANKING_URL;
			API.getRankingWithHeat({ onSuccess: onSuccess, host: rank_host,  heat_id: heat.id })
		} ,5000);
		}
	},[heat]);


	const goal = (
		<img className={"goal-flag"} src={"/goal_flag.png"}  alt={"goal"} />
	)

	const side_panel_ranking = (
		<>
    { course ? course.lap > 1 && (
			<div className={'lap-head'} style={{ display: "flex", alignItems: "center" }}>
				<div className={"lap-disp"} > LAP &nbsp;&nbsp;&nbsp; <span style={{color:"rgba(255, 127, 80, 1)"}}>{g_ranks.length > 0 ? ( Number(course.lap)-1 < Number(max_lap)+1 ? "FINAL" : max_lap+1):""}</span> { g_ranks.length > 0 ? "/"+ course.lap : "" }  </div>
			</div>
      ):<></>
    }


      <Grid container style={{ fontWeight: 'bold', padding: '10px 0' }}>
        <Grid item xs={1}>&nbsp;</Grid>  {/* 左側の空白 */}
        <Grid item xs={1}>&nbsp;</Grid>  {/* 左側の空白 */}
        <Grid item xs={3}>No#</Grid>
        <Grid item xs={2}>
          Spd<span style={{ fontSize: '8px' }}>(km/h)</span>
        </Grid>
        <Grid item xs={2}>
          Dist<span style={{ fontSize: '8px' }}>(m)</span>
        </Grid>
        <Grid item xs={3}>Name</Grid>
      </Grid>


			{g_ranks.length > 0 ? (
				g_ranks.map((r) => (
          r.showMarker && (
          <div key={r.id}>
					<button key={r.id} className={r.ischecked ? 'btn-athlete-selected':'btn-athlete'} onClick={()=>{handleChangeSelectedDevice(r.id); setWindLine({deviceId: r.id});}} >
						<Grid container >
							<Grid  item xs={1}>
								<span className={'ranking-rank'} style={{border: `2pt solid ${r.color_code}`}} >{r.rank}</span>
							</Grid>
							<Grid  item xs={1}style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
								<img className={'nation-flag-2'} src={`/NationalFlag/${r.nation.toLowerCase()}.png`} alt={r.nation} />
							</Grid>
							<Grid  item xs={3}>
								<span className={r.ischecked ? 'ranking-athlete-no-selected' : 'ranking-athlete-no'}>   {r.sailno}</span>
							</Grid>
							<Grid  item xs={2}>
								<span className={r.is_max? 'ranking-spd-max' : 'ranking-spd'} > {r.speed_5s} </span>
							</Grid>
							<Grid  item xs={2}>
								<span className={'ranking-dist'}> {r.is_goal ? goal : r.fwd_dist}</span>
							</Grid>
							<Grid  item xs={3}>
								<span className={'ranking-name'}> {r.name} </span>
							</Grid>
						</Grid>
					</button>
          </div>
          )//showMarker
				))
			) : (
				<>no data.</>
			)}
		</>
	);


  return (
		<>
		{side_panel_ranking}
		</>
	)
}
