
//標高を求める関数
export const getElevation = async (lng, lat, map) => {
	if (map) {
		const elevation = map.queryTerrainElevation([lng, lat], { exaggerated: false });
		return elevation;
	}
	return null;
};


// 地球の半径（メートル）
const EARTH_RADIUS = 6371000;

// 緯度経度から2点間の距離を計算する
const getDistance = (lat1, lon1, lat2, lon2) => {
  const rad = Math.PI / 180;
  const dLat = (lat2 - lat1) * rad;
  const dLon = (lon2 - lon1) * rad;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * rad) * Math.cos(lat2 * rad) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS * c;
};

// 緯度経度を補完する関数
export const interpolatePoints = (lat1, lon1, lat2, lon2, interval) => {
  const distance = getDistance(lat1, lon1, lat2, lon2);
  const numPoints = Math.floor(distance / interval);
  const points = [];

  for (let i = 0; i <= numPoints; i++) {
    const fraction = i * interval / distance;
    const lat = lat1 + fraction * (lat2 - lat1);
    const lon = lon1 + fraction * (lon2 - lon1);
    points.push([lon, lat]);
  }

  return points;
};

// エレベーション配列から最寄りのインデックスを返す
// k-dツリーのノード
class KDNode {
  constructor(point, left = null, right = null) {
    this.point = point; // {lat, lon, elevation}
    this.left = left;
    this.right = right;
  }
}

// k-dツリーの構築
const buildKDTree = (points, depth = 0) => {
  if (points.length === 0) return null;

  // どの軸で分割するかを決定
  const axis = depth % 2;

  // 座標でソートし中央値を選択
  points.sort((a, b) => (axis === 0 ? a.lat - b.lat : a.lon - b.lon));
  const medianIndex = Math.floor(points.length / 2);

  // 新しいノードを作成し、再帰的に左と右のサブツリーを構築
  return new KDNode(
    points[medianIndex],
    buildKDTree(points.slice(0, medianIndex), depth + 1),
    buildKDTree(points.slice(medianIndex + 1), depth + 1)
  );
};

// 最近傍検索
const findNearest = (node, point, depth = 0, best = { node: null, dist: Infinity }) => {
  if (node === null) return best;

  // 現在のノードの距離を計算
  const axis = depth % 2;
  const dist = Math.sqrt(
    Math.pow(point.lat - node.point.lat, 2) + Math.pow(point.lon - node.point.lon, 2)
  );

  // 現在のノードが最良の点よりも近い場合は更新
  if (dist < best.dist) {
    best = { node, dist };
  }

  // 左または右のサブツリーを探索
  const nextBest = axis === 0
    ? (point.lat < node.point.lat ? node.left : node.right)
    : (point.lon < node.point.lon ? node.left : node.right);

  const otherBest = axis === 0
    ? (point.lat < node.point.lat ? node.right : node.left)
    : (point.lon < node.point.lon ? node.right : node.left);

  best = findNearest(nextBest, point, depth + 1, best);

  if (Math.abs(axis === 0 ? point.lat - node.point.lat : point.lon - node.point.lon) < best.dist) {
    best = findNearest(otherBest, point, depth + 1, best);
  }

  return best;
};

// エレベーション配列から最寄りのインデックスを返す
export const getNearestElevation = (elevation, lon, lat) => {
  const points = elevation.map(p => ({ lat: p.lat, lon: p.lon, elevation: p.elevation }));
  const root = buildKDTree(points);
  const nearest = findNearest(root, { lat, lon });

  return elevation.findIndex(p => p.lat === nearest.node.point.lat && p.lon === nearest.node.point.lon);
};

