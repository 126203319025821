import * as React from 'react';
import {useState, useEffect, useCallback} from 'react';
import { isAthlete } from '../../initialize/device_helpers.js';
import {to_d} from '../../helpers';
import Grid from '@mui/material/Grid';

export default function ReplaySidePanelRoadRace( props ) {

  const { devices, handleChangeSelectedDevice, selectedValue } = props;

  //map -> list
  let devs = [...devices].map( ([v,k]) => {
    return k
  })

  //sort
  let sort_target = 'no'; //ソート対象を変数で設定
  devs.sort((a, b) => a[sort_target] - b[sort_target]);
  devs.sort((a, b) => b.ischecked - a.ischecked);//選択されたものは先頭へ


  //Find max speed
  let max_id = "";
  let maxspd = 0;
  devs.forEach((device, i)=>{
    if(maxspd < device.speed_5s){
      maxspd = device.speed_5s;
      max_id = device.id;
    }
  });

	let rank=1;

	return(
		<>

			<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No#&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SPD<span style={{fontSize:'8px'}}>(km/h)</span></div>
			{devs.length > 0 ? 
			devs.map( (d) => (
          d.showMarker &&
					isAthlete(d.category) &&
					<button key={d.serial} className={d.ischecked ? 'btn-athlete-selected':'btn-athlete'} onClick={()=>{handleChangeSelectedDevice(d.serial)}} >
						<Grid container >
							<Grid  item xs={1}>
								<span className={'ranking-rank'}>{rank++}</span>
							</Grid>
							<Grid  item xs={1}>
								<img
									style={{border: `2pt solid ${d.color_code}`}}
									className={'team-flag'}
									src={`/j-pro/${d.club}.png`}
									alt={d.club}
									onError={(e) => {
										e.target.src = '/j-pro/default.png';
									}}
								/>
							</Grid>
							<Grid  item xs={4}>
								<span className={'ranking-firstname'}>{d.nation=="JPN"? d.lastname : d.firstname}</span>
							</Grid>
							<Grid  item xs={2}>
								<span className={d.ischecked ? 'ranking-athlete-no-selected' : 'ranking-athlete-no'}>   {d.no}</span>
							</Grid>
							<Grid style={{ textAlign: 'center' }}  item xs={4}>
								<span className={d.id == max_id ? 'athlete-spd-max'  : 'athlete-spd'}>{d.speed_5s}</span>
							</Grid>
						</Grid>
					</button>
				))
			: 
				<>no data.</>
			}
		</>
	)
}
