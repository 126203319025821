import * as React from 'react';

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function IconFocus(props) {
  const { objectSize, offset } = props;
  const circleSize = objectSize * 2;
  const defaultOffset = -circleSize / 2;

  return(
    <svg
        height={circleSize}
        viewBox="0 0 32 32"
        style={{
        position: 'absolute',
        stroke: 'none',
        transform: `translate(${defaultOffset + offset.x}px,${defaultOffset + offset.y}px )`
        }}
    >
        <circle cx="15" cy="15" r="10" fillOpacity="0%" stroke="aqua" />
    </svg>
  );
}

export default React.memo(IconFocus);
