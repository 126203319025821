import * as React from 'react';
import IconFocus from './icon_focus';
import IconEmergency from './icon_emergency';

const D_ARROW = 'M 476.811,492.379 268.639,8.332 c -2.172,-5.047 -7.141,-8.328 -12.641,-8.328 -5.5,0 -10.469,3.281 -12.641,8.328 L 35.186,492.379 c -2.656,5.625 -1.203,12.344 3.547,16.359 4.766,4.016 11.625,4.359 16.734,0.813 L 255.998,370.519 456.545,509.551 c 5.109,3.547 11.969,3.203 16.734,-0.813 4.75,-4.015 6.188,-10.734 3.532,-16.359 z';
const SIZE = 25;
const offsetFocus = -25;

function IconArrow(props) {
  const {offset, rotate, color, focus, onClick, emergency, showMarker} = props;
  const defaultOffset = -SIZE / 2;

  return(
    <>
      <svg
        height={SIZE}
        viewBox="0 0 500 500"
        style={{
          cursor: 'pointer',
          stroke: 'none',
          opacity: focus || showMarker ? .999 : .45,
          transform: `translate(${defaultOffset + offset.x + 12}px,${defaultOffset + offset.y + 17}px) rotate(${rotate}deg)`
        }}
        onClick={onClick}
      >
        <path d={D_ARROW} fill={color}/>
      </svg>

      { focus && 
        <IconFocus objectSize={SIZE} offset={{x: offset.x + 12 + offsetFocus, y: offset.y + 17}} />
      }
      {emergency && <IconEmergency  objectSize={SIZE} offset={{x: offset.x+12 + offsetFocus, y: offset.y+17}} />}
    </>
  );
}

export default React.memo(IconArrow);


/*
      { focus && 
        <IconFocus objectSize={SIZE} offset={{x: offset.x + 15 + offsetFocus, y: offset.y + 10}} />
      }
*/
