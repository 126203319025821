import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { formatTime, is_counttime, is_training } from '../../helpers';
import { handleCopySpeeds, handleCopySpeeds60 } from './copy_data';

/** 
 * グラフデータ用スライダー
 */   
export default class SliderComponent extends Component {

  static propTypes = {
    dataRange: PropTypes.object.isRequired,
    selectionRange: PropTypes.object,
    handleSelectDataRange: PropTypes.func.isRequired,
    values: PropTypes.number.isRequired,
    is_mobile: PropTypes.bool,
    startedTime: PropTypes.string,  // startedTime を number 型に設定
  };

  formatCountTime = (diff) => {
    const absDiff = Math.abs(diff);
    const hours = Math.floor(absDiff / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((absDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((absDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');

    return `${diff < 0 ? '-' : ''}${hours}:${minutes}:${seconds}`;
  }

  render() {
    const { is_mobile, values, startedTime, spinner, handleChangePlaySpeed, incrementSecond, handleClickPlay, isPlaying, devices, currentReplayTimeSec, handleSelectDataRange } = this.props;

    let displayTime;
		// passedTimesがnullまたはundefinedであれば、空配列にフォールバック
		const passedTimes = this.props.passedTimes || [];
    
    if (is_counttime() && startedTime != undefined) {
      const startedTimeMs = new Date(startedTime).getTime();  // startedTime をミリ秒に変換
      const diff = values * 1000 - startedTimeMs;  // `values` の単位が秒なのでミリ秒に変換して引き算

      displayTime = this.formatCountTime(diff);  // 差分を00:00:00形式で表示
    } else {
      displayTime = formatTime(new Date(values), true);  // `values` を秒単位からミリ秒に変換
    }

    // startedTime のスライダー上での位置を算出
    const startedTimePosition = new Date(startedTime).getTime()/1000;  // startedTime を秒単位に変換

    // marks の定義
    const marks = [
      {
        value: startedTimePosition,
        label: <span style={{ color: '#FFFFFF' }}>S</span>,  // 'S' を表示
      },
			...passedTimes.map((item) => ({
				value: new Date(item.passed_time).getTime()/1000,  // passed_timeをパースしてvalueに
				label: <span style={{ color: '#FFFFFF' }}>{item.name}</span>  // nameをlabelに
			}))
    ];


    return (
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000000',
        zIndex: 1000 // Adjust this value as needed to ensure it appears above other elements
      }}>
      <Grid container spacing={1}> 
        <Grid item xs={is_mobile ? 3 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }} className={'nowtime'}>
          {displayTime} 
        </Grid>

        <Grid item xs={is_training() ? 3 : 1} style={{'background-color':'#000000'}} >
          {spinner == false ?
            (<select className={'play_speed'} onChange={(e)=>handleChangePlaySpeed(e)} defaultValue={incrementSecond}>
              <option value="1">x1</option>
              <option value="5" >x5</option>
              <option value="10">x10</option>
              <option value="30">x30</option>
              <option value="60">x60</option>
              <option value="120">x120</option>
              <option value="300">x300</option>
            </select>)
            : (<></>)
          }
          {is_mobile
            ? <></>
            : isPlaying
              ? <button onClick={()=>handleClickPlay()}> Stop </button>
              : <button onClick={()=>handleClickPlay()}> Play </button>
          }
          { is_training() && <button onClick={()=>handleCopySpeeds(devices, currentReplayTimeSec)}> Copy[+-20] </button>}
          { is_training() && <button onClick={()=>handleCopySpeeds60(devices, currentReplayTimeSec)}> Copy[0-60] </button>}
        </Grid>
        <Grid item xs={is_mobile ? is_training() ? 4 : 6 : is_training() ? 8 : 9} style={{ backgroundColor: '#000000' }}>
          <Slider
            min={this.props.dataRange.min}
            max={this.props.dataRange.max}
            step={this.props.dataRange.step}
            value={this.props.values}
            onChange={(event, newValue) =>handleSelectDataRange(newValue)}
            aria-labelledby="continuous-slider"
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => formatTime(value, true)}  // `value` を秒からミリ秒に変換してツールチップで表示
            marks={marks}  // startedTime の位置に 'S' を表示
          />
        </Grid>
        <Grid item xs={is_mobile ? 2 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }}>
          {is_mobile
            ? isPlaying
              ? <button onClick={()=>handleClickPlay()}> Stop </button>
              : <button onClick={()=>handleClickPlay()}> Play </button>
            : <span style={{ color: '#ffffff' }}>REPLAY</span>
          }
        </Grid>     
      </Grid>
      </div>
    );
  }
}

