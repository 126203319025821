import * as React from 'react';
import PanelHeader from "./panel_header";
import PanelContentMap from "./panel_content_map";
import PanelContentRoute from "./panel_content_route";
import PanelContentMapDetails from "./panel_content_map_details";
import PanelContentCheckpoint from "./panel_checkpoint";

function Panel(props) {
  const {setMenuVisible, setStyle, visibleRoutes, setVisibleRoutes, focusCamera, setFocusCamera, showCheckpoint, setShowCheckpoint} = props;

  return (
    <>
        <div className="panel">
          <PanelHeader  setMenuVisible={setMenuVisible} />
          <PanelContentCheckpoint showCheckpoint={showCheckpoint} setShowCheckpoint={setShowCheckpoint} />
          <PanelContentMap setStyle={setStyle} />
          <PanelContentRoute visibleRoutes={visibleRoutes} setVisibleRoutes={setVisibleRoutes} />
          <PanelContentMapDetails focusCamera={focusCamera} setFocusCamera={setFocusCamera}/>
        </div>
    </>
  );
}

export default Panel;
