import { useEffect, useState } from 'react';

export const useYoutubeState = (delay) => {
  const [videoIds, setVideoIds] = useState([]);
  const [videoOptions, setVideoOptions] = useState([]);
  const [isAutoPlay, setIsAutoPlay] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // 各パラメータの取得
    const youtube = ['1', '2', '3'].map((i) => queryParams.get(`youtube${i}`));
    const youtubeOp = ['1', '2', '3'].map((i) => queryParams.get(`youtube${i}op`));
    const autoplay = queryParams.get('autoplay') === '1';

    // ステートにセットする配列を生成
    // videoOptionsはvideoIdsに依存するので、videoIdsの数だけ生成する
    const ids = [];
    const options = [];
    for(let i = 0; i < youtube.length; i++) {
      if(youtube[i]) {
        ids.push(youtube[i]);
        options.push(
          delay ? Number(youtubeOp[i]) + Number(delay) : youtubeOp[i]
        );
      }
    }

    setVideoIds(ids);
    setVideoOptions(options);
    setIsAutoPlay(autoplay);
    
  }, [delay]);

  return { videoIds, videoOptions, isAutoPlay };
};
