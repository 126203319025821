// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const lineLayer =  {
         "id": "routes",
         "type": "line",
         "source": "data",
         "layout": {
                "line-join": "round",
                "line-cap": "round"
         },
         "paint": {
                "line-color": ["get", "color"],
                "line-width": 1.5,
                "line-opacity": 0.7,
         }
};

export const StartGoalLineLayer =  {
         "id": "StartGoal",
         "type": "line",
         "source": "data",
         "layout": {
                "line-join": "round",
                "line-cap": "round",
         },
         "paint": {
                "line-color": ["get", "color"],
                "line-width": ["get", "width"],
                "line-opacity": ["get", "opacity"],
         }
};

export const SymbolLayer =  {
          "id": "Labels",
          "type": "symbol",
          "source": "data",
					'layout': {
						'text-field': ['get', 'name'],
						'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
						'text-radial-offset': 0.5,
						'text-justify': 'auto',
					},
         "paint": {
						'text-color': "#FFFFFF",
         }
};
