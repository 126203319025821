import React from 'react';
import Iframe from 'react-iframe';

import { generateYoutubeUrl } from '../../helpers';
import { useYoutubeState } from '../../hooks/useYoutubeState';

const liveWidth = 352;
const liveHeight = 198;

const iframeWrapperStyle = {
  display: 'block',
  overflow: 'hidden',
  width: liveWidth,
  height: liveHeight,
  borderRadius: '10px',
  transform: 'translateY(0px)',
  border: '3px solid #eee',
};

const VideoMobile = (props) => {
  const { delay } = props;
  const { videoIds, videoOptions, isAutoPlay } = useYoutubeState(delay);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "10px" }}>
      {videoIds.map((id, index) => (
        <div key={id} style={{ margin: '24px 0' }}>
          <div style={iframeWrapperStyle}>
            <Iframe
              url={generateYoutubeUrl(id, isAutoPlay, videoOptions[index])}
              width={liveWidth}
              height={liveHeight}
              display="initial"
              position="relative"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(VideoMobile);
